import React ,{useEffect} from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useDispatch ,useSelector } from 'react-redux';
import { getCustomerDetailsAction } from '../actions/customerAccountAction';
import { getMemberDetailsAction } from '../actions/memberAction';
import { loadCustomerAccountNumberAction } from '../actions/customerAccountAction';
import Swal from 'sweetalert2'
import Loader from '../Components/Loader';
import moment from 'moment'
import { LOAD_AUTO_RESET ,CUSTOMER_ACCOUNTS_RESET_DETAILS ,ACCOUNT_TRANSACTION_RESET ,ACCOUNT_BALANCE_RESET ,ACCOUNT_AUTO_SERVICE_RESET } from '../constants/customerAccountConstants';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import { FcCheckmark } from "react-icons/fc";
import { FcMoneyTransfer } from "react-icons/fc"
import { ToWords } from 'to-words'
import { useState } from 'react';
import { DepositAccounAction ,SaveAccountTransaction ,CustomerAccountBalanceAction ,userAccountBalanceAction ,AccountAountServicerAction 
  ,getMemberCorAaccountSignatoryDetailsAction ,customerAccountHistory} from '../actions/customerAccountAction';
import { FaUserTie } from "react-icons/fa";
import { cash ,cashGH ,numberFormat} from './NumberFormat';
import { SaveUserTranactions ,valutAprovalAction ,TreasureTodayAction ,userVaultBalanceAction ,TreasureTodayActionSetting,UserVault } from '../actions/vaultAction';
import { VAULT_RESET_SAVE,UPDATE_USER_VAULT_RESET ,VAULT_INFO_RESET} from '../constants/vaultConstants';
import { FaUserLarge } from "react-icons/fa6";
import { PiSignatureThin } from "react-icons/pi";
import sigd from '../images/sigd.jpg'
import profile from '../images/profile-img.jpg'
import { sms_Deposit } from './Message';
import { FcHighPriority } from "react-icons/fc";
import CurrencyInput from 'react-currency-input-field';
import { MdEdit } from "react-icons/md";
import { IoReceiptOutline } from "react-icons/io5";
import { aprMessageAction } from '../actions/approvalAction';
import { messageListAction } from '../actions/approvalAction';
import { APPROVAL_MESSAGE_SAVE_REST } from '../constants/approvalConstants';
import useSound from 'use-sound';
import messagersent from '../images/messagersent.wav'
import { MobileBankAccess ,MobileBankAccessLoad } from '../actions/vaultAction';
import { TREASURE_SETTING_RESET } from '../constants/vaultConstants';
import { GoSearch } from "react-icons/go";
import { FaRegEdit } from "react-icons/fa";
import { TbReport } from "react-icons/tb";
import { MOBAccountTransactionAction ,MOBTransactionUpdateAction } from '../actions/customerAccountAction';
import { MdDeleteForever } from "react-icons/md";
import { IoCheckmark } from "react-icons/io5";
import { FaXmark } from "react-icons/fa6";
import { IoMdCheckmarkCircle } from "react-icons/io";

function AccountDeposit() {

  let dat2 = moment().format('YYYY-MM-DD')
  let dat=moment().format('MMMM Do YYYY, h:mm:ss a')


  const [playOn] = useSound(
    messagersent,
    { volume: 0.25 }
);

  const [realDeposit ,setrealDeposit]=useState(false)
  const [mobDeposit ,setmobDeposit]=useState(false)
  const [mobbanker ,setmobbanker]=useState()
  const [Teller ,setTeller]=useState()
  const [totalDeposit ,settotalDeposit]=useState()
  const [TransactionCount ,setTransactionCount]=useState()
  const [Status ,setStatus]=useState('')
  const [transDate ,settransDate]=useState()

  const [tel ,settel]=useState('')
  const [ty ,setty]=useState('')
  const [tyid ,settyid]=useState('')
  const [mobUser, setmobUser]=useState('')
  const [mobmax, setmobmax]=useState('')

  const [deposit ,setdeposit]=useState(0.00)
  const [withdrawal ,setdwithdrawal]=useState(0.00)

  const [depositSeting ,setdepositSeting]=useState()

  const [showUnapproved ,setshowUnapproved]=useState(false)

  const [outDatedTr ,setoutDatedTr]=useState([])

  const [showdepwireport ,setshowdepwireport]=useState(false)

  const handleCloseshowdepwireport=()=>{
    setshowdepwireport(false);
  }

  const handleOpenshowdepwireport=()=>{
    setshowdepwireport(true);
  }



  const [dw ,setdw]=useState(false)

  let gettr
  let cname=''
  let acno=''
  let drcr=''
  let desc=''

  const [userBala,setuserBala]=useState(0.00)
  
  const [balance ,setBalance]=useState(0.00)


  const [n200 ,setn200]=useState('')
  const [t200 ,sett200]=useState(0)

  const [n100 ,setn100]=useState('')
  const [t100 ,sett100]=useState(0)
 
  const [n50 ,setn50]=useState('')
  const [t50 ,sett50]=useState(0)

  const [n20 ,setn20]=useState('')
  const [t20 ,sett20]=useState(0)

  const [n10 ,setn10]=useState('')
  const [t10 ,sett10]=useState(0)

  const [n5 ,setn5]=useState('')
  const [t5 ,sett5]=useState(0)

  const [n2 ,setn2]=useState('')
  const [t2 ,sett2]=useState(0)

  const [n1 ,setn1]=useState('')
  const [t1 ,sett1]=useState(0)



  const [c2 ,setc2]=useState('')
  const [tc2 ,settc2]=useState(0)

  const [c1 ,setc1]=useState('')
  const [tc1 ,settc1]=useState(0)

  const [c05 ,setc05]=useState('')
  const [tc05 ,settc05]=useState(0)

  const [c02 ,set02]=useState('')
  const [tcc02 ,setc02]=useState(0)

  const [c01 ,setc01]=useState('')
  const [tcc01 ,settcc01]=useState(0)

  const [notetotal ,setnotetotal]=useState(0)

  const [Coinstotal ,setCoinstotal]=useState(0)

  const [runtotal , setruntotal]=useState(false)

  const [gradeotal , setgradeotal]=useState(0)

  const [getSaveDe ,setgetSaveDe]=useState(false)

 //const [amount ,setamount]=useState(0)

 const [showTreasurBal ,setshowTreasurBal]=useState(false)

 const [cad ,setcad]=useState(false)
   
  const totals =()=>{

    var note_total=(Number(t200) + Number(t100) +Number(t50) + Number(t20) + Number(t10) + Number(t5) +Number(t2) + Number(t1))
    setnotetotal(note_total)

    var Coins_total=(Number(tc2) + Number(tc1) +Number(tc05) + Number(tcc02) + Number(tcc01) )
    setCoinstotal(Coins_total)

    setruntotal(false)


    setgradeotal(Number(note_total) + Number(Coins_total) )

    //setamount(Number(note_total) + Number(Coins_total))

  }

 useEffect(()=>{

  if(runtotal){
    totals()
  }
  

 },[runtotal])




 useEffect(()=>{

  let words = toWords.convert(gradeotal, { currency: true });
  
    setamountToWord(words)


 },[gradeotal]) 

 
 const [showsearch ,setshowsearch]=useState(false)
    
    const denomination=()=>{
        setshowsearch(true)
        
      }
     
      const closersearch=()=>{
        setshowsearch(false)
        setruntotal(false)
        setgetSaveDe(false)
        setcad(false)
       // clearDen()

      } 

      const clearDen =()=>{
    
      setn200('')
      sett200(0)
    
      setn100('')
      sett100(0)
     
      setn50('')
      sett50(0)
    
      setn20('')
      sett20(0)
    
      setn10('')
      sett10(0)
    
      setn5('')
      sett5(0)
    
      setn2('')
      sett2(0)
    
      setn1('')
      sett1(0)
    
      setc2('')
      settc2(0)
    
      setc1('')
      settc1(0)
    
      setc05('')
      settc05(0)
    
      set02('')
      setc02(0)
    
      setc01('')
      settcc01(0)
    
      setnotetotal(0)
    
      setCoinstotal(0)
    
      setruntotal(false)
    
      setgradeotal(0)
    
     setamountToWord('') 
    
    // setamount(0)
    
    
      }


    const [showHistory, setshowHistory]=useState(false)
 

    const openShowHistory =()=>{
      setshowHistory(true)
      dispatch(customerAccountHistory(CustomerAccountDetails.account_number))
    }

    const closeShowHistory =()=>{
      setshowHistory(false)
    }


    const dispatch=useDispatch()
    let save_aproval="True"

    const toWords = new ToWords({
        localeCode: 'en-US',
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
          currencyOptions: {
            // can be used to override defaults for the selected locale
            name: 'Ghana Cedis',
            plural: 'Ghana Cedis',
            symbol: '₹',
            fractionalUnit: {
              name: 'Pesewas',
              plural: 'Pesewas',
              symbol: '',
            },
          },
        },
      })
    
    const [amount ,setAmount]=useState(0)

    const [amountToWord ,setamountToWord]=useState('') 
    
    let Today = moment().format('YYYY-MM-DD')
    
    const [PendingTreasur ,setPendingTreasur]=useState()

    const [upat ,setupat]=useState()

    const [depoby ,setdepoby]=useState('')

    const [show ,setshow]=useState(false)
    const [showcor ,setshowcor]=useState(false)

    const [SMS ,setSMS]=useState('')
    
    const successMessage=(message) => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: message ,
    showConfirmButton: false,
    timer: 10000
  });
    }


    const ErrorMessage=(message) => {
  Swal.fire({
    position: "center",
    icon: "error",
    title: message ,
    showConfirmButton: false,
    timer: 1500000
    
  
  });

    }


  const handleCloseModal =()=>{

       setshow(false)
     
  }

  const handleopenModal =()=>{
      
      setshow(true) 

  }


  const handleCloseModalcor =()=>{
      setshowcor(false)
  }

  const handleopenModalcor =()=>{
      
      setshowcor(true) 
     
  }


  const clearCor=()=>{
    setdepoby('')
    settel('')
    setty('')
    settyid('')
  }


  const getholderInfo =()=>{
    try {
      clearCor()
      setdepoby(CustomerAccountDetails.account_name)
      settel(CustomerAccountDetails.tel1)
      setty(CustomerAccountDetails.card_ty)
      settyid(CustomerAccountDetails.card_Id)

      handleCloseModal()
      
    } catch (error) {
      
    }
  }

  const getsig1Information=()=>{

    try {
      clearCor()
      setdepoby(CustomerAccountDetails.na1)
      settel(CustomerAccountDetails.tel1)
      setty(CustomerAccountDetails.card_ty)
      settyid(CustomerAccountDetails.card_Id)

      handleCloseModalcor()
      
    } catch (error) {
      
    }

  }


  const getsig2Information=()=>{

    try {
      clearCor()
      setdepoby(CustomerAccountDetails.na2)
      settel(CustomerAccountDetails.tel2)
      setty(CustomerAccountDetails.card_ty_2)
      settyid(CustomerAccountDetails.card_Id_2)

      handleCloseModalcor()
      
    } catch (error) {
      
    }

  }


  const getsig3Information=()=>{

    try {
      
      if(CustomerAccountDetails.na3=='' || CustomerAccountDetails.tel3=='' || CustomerAccountDetails.card_ty_3==''|| CustomerAccountDetails.card_Id_3==''){

        ErrorMessage("No Account Signatory's Information Found")

      }else{

        clearCor()

        setdepoby(CustomerAccountDetails.na3)
        settel(CustomerAccountDetails.tel3)
        setty(CustomerAccountDetails.card_ty_3)
        settyid(CustomerAccountDetails.card_Id_3)
        handleCloseModalcor()

      }
     
      
    } catch (error) {
      
    }

  }


  const cloaseUnaprove=()=>{
    //setshowUnapproved(false)
    dispatch({type:VAULT_INFO_RESET})
  }

    
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo }=userLogin

    const CustomerAccountDetailsReducer = useSelector(state => state. CustomerAccountDetailsReducer)
    const {CustomerAccountDetails ,CustomerAccountDetailsLoading}= CustomerAccountDetailsReducer
    
  
    const CustomerAccountNumberReducer = useSelector(state => state.CustomerAccountNumberReducer)
    const {accountNumber}=CustomerAccountNumberReducer

    const [cAccNo,setcAccNo]=useState(accountNumber)


    const CustomerAccountBalanceReducer = useSelector(state => state.CustomerAccountBalanceReducer)
    const {accountbalance,accountbalanceLoading}=CustomerAccountBalanceReducer


    const accountTransactionReducer = useSelector(state => state.accountTransactionReducer)
    const {transactionLoading,transactionInfo ,depositsu}=accountTransactionReducer


    const USERAccountBalanceReducer = useSelector(state => state.USERAccountBalanceReducer)
    const {userbalance,userbalanceLoading }=USERAccountBalanceReducer


    const TreasureTodayReducer = useSelector(state => state.TreasureTodayReducer)
    const { TreasureToday,TreasureTodayLoading }=TreasureTodayReducer


    const TreasureTodaySettingReducer = useSelector(state => state.TreasureTodaySettingReducer)
    const { TreasureSettingToday,TreasureTodaySettingLoading }=TreasureTodaySettingReducer
    

    const saveVaultTransactionReducer = useSelector(state => state.saveVaultTransactionReducer)
    const { saveVaultTransactionLoading,saveVault ,VaultTransaction }=saveVaultTransactionReducer


    const UserTreasureTodayReducer = useSelector(state => state.UserTreasureTodayReducer)
    const { UserTreasureToday ,UserTreasureTodayLoading }=UserTreasureTodayReducer

    const CorporateAccountsignatoryDetailsReducer = useSelector(state => state. CorporateAccountsignatoryDetailsReducer)
    const {CorporateAccountsignatoryDetailsLoading ,CorporateAccountsignatoryDetails }= CorporateAccountsignatoryDetailsReducer


    const accountAutoServiceReducer = useSelector(state => state. accountAutoServiceReducer)
    const {accountAutoServicerLoading ,accountAutoServicer }= accountAutoServiceReducer

    
    const updateUserVaultReducer = useSelector(state => state. updateUserVaultReducer)
    const {updateUserVaultLoading ,updateUserVault,valutup }= updateUserVaultReducer

    
    const vaultReducer = useSelector(state => state.vaultReducer)
    const { vaultLoading,vault }=vaultReducer


    const CustomerAccountTrancationReducer = useSelector(state => state. CustomerAccountTrancationReducer)
    const {Account_histoeyLoading ,Account_histoey }= CustomerAccountTrancationReducer

    const userProfileReducer = useSelector(state => state.userProfileReducer)
    const { userProfileInfo } = userProfileReducer


    const save_Message_Reducer = useSelector(state => state.save_Message_Reducer)
    const { messagesave, messagesaveLoading, savesuccess } = save_Message_Reducer


    const apro_Message_Reducer = useSelector(state => state.apro_Message_Reducer)
    const { aproMessageLoading, message, success } = apro_Message_Reducer


    const createUserVaultSettingReducer = useSelector(state => state.createUserVaultSettingReducer)
    const { creatUserSettingValutLoading,creatUserSettingValut,creatUserSettingerror ,creatUserSettingValutSu }=createUserVaultSettingReducer



    const MobileCollReducer = useSelector(state => state.MobileCollReducer)
    const { mobCollToday , mobCollTodayLoading , mobCollTodayerror }=MobileCollReducer

    const MOBAccountTransactionReducer = useSelector(state => state.MOBAccountTransactionReducer)
    const { MOBAccount ,MOBAccountLoading ,MOBAccounterror }=MOBAccountTransactionReducer

    const MOBTransactionUpdateReducer = useSelector(state => state.MOBTransactionUpdateReducer)
    const { mobUpdateTrLoading ,mobUpdateTr  }=MOBTransactionUpdateReducer


    useEffect(()=>{

      if(creatUserSettingValutSu){
        successMessage('Collection Access Granted')
        dispatch({type:TREASURE_SETTING_RESET})
        dispatch(MobileBankAccessLoad(dat2))
      }

      if(creatUserSettingerror){
        ErrorMessage('Collection Access Error')
        dispatch({type:TREASURE_SETTING_RESET})
      }


    },[createUserVaultSettingReducer])
   
    const showUserTreasuer=(PendingTreasur)=>{

    setn200(PendingTreasur.n200)
    sett200((Number(PendingTreasur.n200) * 200))

    setn100(PendingTreasur.n100)
    sett100((Number(PendingTreasur.n100) * 100))

    setn50(PendingTreasur.n50)
    sett50((Number(PendingTreasur.n50) * 50))

    setn20(PendingTreasur.n20)
    sett20((Number(PendingTreasur.n20) * 20))

    setn10(PendingTreasur.n10)
    sett10((Number(PendingTreasur.n10) * 10))

    setn5(PendingTreasur.n5)
    sett5((Number(PendingTreasur.n5) * 5))

    setn2(PendingTreasur.n2)
    sett2((Number(PendingTreasur.n2) * 2))

    setn1(PendingTreasur.n1)
    sett1((Number(PendingTreasur.n1) * 1))

    setc2(PendingTreasur.c2)
    settc2((Number(PendingTreasur.c2) * 2))

    setc1(PendingTreasur.c1)
    settc1((Number(PendingTreasur.c1) * 1))

    setc05(PendingTreasur.c05)
    settc05((Number(PendingTreasur.c05) * 0.5))

    set02(PendingTreasur.c02)
    setc02((Number(PendingTreasur.c02) * 0.2))

    setc01(PendingTreasur.c01)
    settcc01((Number(PendingTreasur.c01) * 0.1))

  
    denomination()

    
    setgetSaveDe(true)
    setruntotal(true)

    console.log(PendingTreasur)
    }

    
    const aprovalVault =()=>{

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Confirm",
        text: "Are You Sure You Want to Approve The Treasure Out Balance?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     
  
    }).then((result) => {
  
        if (result.isConfirmed) {
  
     dispatch(SaveUserTranactions(dat ,dat2 , PendingTreasur.amount , PendingTreasur.amount, 0.00 ,PendingTreasur.branch ,PendingTreasur.vault_code ,PendingTreasur.vault_name ,PendingTreasur.des ,PendingTreasur.user))

      save_aproval=1
      let close_bala=0.00
      let close_status=0
      let deposit=0
      let withdrawal=0
      let aprove_status=0
      let status=1
      let cr = 0

      dispatch(valutAprovalAction({trans_code:PendingTreasur.trans_code ,
        save_aproval,
        close_bala,
        close_status,
        deposit,
        withdrawal,
        aprove_status,
        status,
        cr
      }))

    
      closersearch()

      } else if (result.dismiss === Swal.DismissReason.cancel) {
  
       
        }
        
    });


      
  

    }


  const cashDenomination=()=>{

      if(CustomerAccountDetails.length <=0 ){
          ErrorMessage('No Account Information Found');
      }else{

        denomination()

        setgetSaveDe(false)
        setcad(true)

      }

     

  }

  const totalcash=()=>{

    setAmount(gradeotal)
    closersearch()
  }

    const userCloseVault =(trans_code)=>{

      getTreasure();
      setdw(true)

      save_aproval=1
      let close_bala=userBala
      let close_status=1
      let des='Treasure Out Closing Balance'
      let aprove_status=0
      let status=1
      let cr = 0

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
      confirmButton: "btn btn-success mx-4",
      cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
      title: "Confirm",
      text: "Are You Sure You Want to Close Treasure Balances Today",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
   

  }).then((result) => {

      if (result.isConfirmed) {

        //dispatch(SaveUserTranactions(dat ,dat2 , PendingTreasur.amount , 0.00, userBala ,PendingTreasur.branch ,PendingTreasur.vault_code ,PendingTreasur.vault_name , des ,PendingTreasur.user))

      dispatch(valutAprovalAction({trans_code:trans_code ,
       save_aproval,
       close_bala,
       close_status,
       deposit,
       withdrawal,
       aprove_status,
       status,
       cr
       }))
       
       cloaseUnaprove()
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {

     
      }
      
  });


     

    }

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    //console.log(string, results)
  }
  
  const handleOnHover = (result) => {
    // the item hovered
   // console.log(result)
  }
  
 
  
 
const handleOnSelect = (accountNumber) => {
    
  clear()

   try {
   
    //multiselectRefTracker.current.resetSelectedValues()
    //dispatch(CustomerAccountAutoServicesAction(accountNumber.account_number))
    dispatch(getMemberDetailsAction(accountNumber.customer_id_id))
    dispatch(getCustomerDetailsAction(accountNumber.trans_code))
    dispatch(CustomerAccountBalanceAction(accountNumber.account_number))
    dispatch(AccountAountServicerAction(accountNumber.account_number ,save_aproval))
    dispatch(getMemberCorAaccountSignatoryDetailsAction(accountNumber.customer_id_id))
    
   // navigate(-1)
   } catch (error) {
    
   }
   
  }
  
  const handleOnFocus = () => {
    //console.log('Focused')
  }
  
const formatResult = (accountNumber) => {
    return (
      <>
  
        <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {accountNumber.name} </span>
        <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {accountNumber.account_name} </span>
        
      </>
    )
  }


const clear=()=>{

  dispatch({type:LOAD_AUTO_RESET})
  dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
  dispatch({type:ACCOUNT_BALANCE_RESET})
  dispatch({type:ACCOUNT_AUTO_SERVICE_RESET})

  settel('')
  setdepoby('')
  settel('')
  settyid('')
  setty('')

  dispatch({type:ACCOUNT_TRANSACTION_RESET})
  setAmount(0)
  setamountToWord('')
  setSMS('')
  clearDen()
  
}

const getTreasure=()=>{
  
  let treasure=0.00
  let deposit=0.00
  let withdrawal=0.00
  let bal=0.00

  if( PendingTreasur){
   
    treasure=PendingTreasur.dr
    setshowTreasurBal(true)
  

  }else{

    treasure=0.00
   
  }
  

  //console.log(treasure)

  if( userbalance.length <=0){
    deposit=0.00
    withdrawal=0.00
  }else {
    deposit=userbalance[userbalance.length-1].deposit
    withdrawal=userbalance[userbalance.length-1].withdrawal
  }

  console.log(deposit)
  console.log(withdrawal)
  bal=(Number(treasure) + Number(deposit)- Number(withdrawal))
  setuserBala(bal)
  setdeposit(deposit)
  setdwithdrawal(withdrawal)

  console.log(bal)

  //console.log(userBala)

  setBalance(numberFormat(userBala).split('$')[1])

  //console.log(balance)
}

useEffect(()=>{

  getTreasure();
  

},[USERAccountBalanceReducer,UserTreasureTodayReducer])


  useEffect(()=>{

    dispatch(loadCustomerAccountNumberAction(save_aproval))
    dispatch(TreasureTodayActionSetting(Today))
    dispatch(UserVault(userInfo.username))
    dispatch({type:LOAD_AUTO_RESET})
    dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
    dispatch({type:ACCOUNT_BALANCE_RESET})
    
    dispatch(TreasureTodayAction(dat2))

    gettr=TreasureToday.find(obj=>obj.user==userInfo.username && obj.status==1)

    setPendingTreasur(gettr)

    dispatch(userVaultBalanceAction(Today ,userInfo.username))

     dispatch(messageListAction(userInfo.username))

     dispatch(MobileBankAccessLoad(dat2))
     

    setupat(true)

    getTreasure();

    
     
},[dispatch ])


useEffect(()=>{

  try {

    dispatch(userAccountBalanceAction(Today ,userInfo.username ,gettr.trans_code))

    getTreasure()
    
  } catch (error) {
    
  }
 //console.log(gettr.trans_code)
 


},[gettr])


const [transactionId ,settransactionId]=useState('')
const [showrec ,setshowrec]=useState(false)

useEffect(()=>{
  if(showrec){

    window.open(`http://localhost/report/deposit_withdral_recit.html?${transactionId}`, "_blank");
    setshowrec(false)

  }
},[transactionId])



useEffect(()=>{

if(depositsu && realDeposit){

  dispatch(CustomerAccountBalanceAction(CustomerAccountDetails.account_number))
  dispatch(userAccountBalanceAction(Today ,userInfo.username ,PendingTreasur.trans_code))
 
  successMessage('Deposit Successfully Saved')

  if(SMS){
   
    let tel= SMS.tel
    console.log(tel)
    let cname=CustomerAccountDetails.account_name
    let acno=CustomerAccountDetails.customer_number.substr(4, 5);
    let drcr='Credited'
    let desc='Cash Deposit'
    let dat =moment().format('YYYY-MM-DD ,h:mm:ss a')

    var amountt=numberFormat(amount).split('$')[1]
  
    let bal=0
    if(accountbalance.length <=0){
      bal=numberFormat(Number(amount)).split('$')[1]
    }else{
      bal=numberFormat(Number(accountbalance[0].account_balance)+Number(amount)).split('$')[1]
    }
   

    sms_Deposit(tel ,cname,acno,drcr,amountt,desc,dat,bal)
  }

  let save_aproval=1
        let close_bala=userBala
        let trans_code= PendingTreasur.trans_code
        let close_status=0
        let aprove_status=0
        let status=1
        let cr = 0

  dispatch(valutAprovalAction({trans_code:trans_code ,
    save_aproval,
    close_bala,
    close_status,
    deposit,
    withdrawal,
    aprove_status,
    status,
    cr
    }))

    settransactionId(transactionInfo.trans_code)
    //console.log(transactionInfo.trans_code)
    setshowrec(true)

  settel('')
  setdepoby('')
  settel('')
  settyid('')
  setty('')
  setrealDeposit(false)
  dispatch({type:ACCOUNT_TRANSACTION_RESET})
  setAmount(0)
  setamountToWord('')
  setSMS('')
  clearDen()
}

},[accountTransactionReducer,CustomerAccountBalanceReducer,CustomerAccountDetailsReducer])


useEffect(()=>{

  if(depositsu && mobDeposit){
  
    dispatch(CustomerAccountBalanceAction(CustomerAccountDetails.account_number))
    dispatch(userAccountBalanceAction(Today ,userInfo.username ,PendingTreasur.trans_code))
   
    successMessage('Deposit Successfully Saved')
  
    settel('')
    setdepoby('')
    settel('')
    settyid('')
    setty('')
    setmobDeposit(false)
    dispatch({type:ACCOUNT_TRANSACTION_RESET})
    setAmount(0)
    setamountToWord('')
    setSMS('')
    clearDen()
  }
  
  },[accountTransactionReducer])
  


useEffect(()=>{

  if(saveVault){

      successMessage('User Treasure Balances Successfully Updated')
      dispatch({type:VAULT_RESET_SAVE})

      dispatch(TreasureTodayAction(dat2))

      dispatch(UserVault(userInfo.username))

      //window.location.reload();

      setPendingTreasur(TreasureToday.find(obj=>obj.user==userInfo.username && obj.status==1))

      setupat(false)

      dispatch(userVaultBalanceAction(Today ,userInfo.username))

  

    }
},[saveVaultTransactionReducer])



useEffect(()=>{

  const smss=accountAutoServicer.find(obj=>obj.service_name =='SMS alert on transactions')

  if(smss){
    setSMS(smss)
  }else {
    setSMS(smss)
  }

  console.log(smss)

},[accountAutoServiceReducer])


useEffect(()=>{

  if(valutup && dw){

    
    successMessage('User Treasure Balances Close Successfully')

   
    dispatch(loadCustomerAccountNumberAction(save_aproval))
    dispatch({type:LOAD_AUTO_RESET})
    dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
    dispatch({type:ACCOUNT_BALANCE_RESET})
    dispatch({type:UPDATE_USER_VAULT_RESET})

    
    dispatch(TreasureTodayAction(dat2))
    
    if(PendingTreasur){

      dispatch(userAccountBalanceAction(Today ,userInfo.username ,PendingTreasur.id))
    }
   

    dispatch(userVaultBalanceAction(Today ,userInfo.username))

    //console.log(Today)

    setPendingTreasur(TreasureToday.find(obj=>obj.user==userInfo.username && obj.status==1))

    setupat(false)
    setdw(false)

  }

  if(valutup){
    dispatch({type:UPDATE_USER_VAULT_RESET})

  }

 

},[updateUserVaultReducer ,vaultReducer])


useEffect(()=>{

  const getUserSettings=TreasureSettingToday.filter(obj=>obj.user==userInfo.username)

 setdepositSeting(getUserSettings[getUserSettings.length-1])
 //console.log(getUserSettings[getUserSettings.length-1])

},[TreasureTodaySettingReducer])


useEffect(()=>{

  let user=userInfo.username
  const uaproveVault=vault.filter(obj=>obj.save_aproval==1 && obj.dat2!=dat2 &&  obj.close_status==0 && obj.user==user)

  setoutDatedTr(uaproveVault)

  if(uaproveVault.length >0){

    setshowUnapproved(true)
    

   //console.log(uaproveVault)
  }else {
    setshowUnapproved(false)
  }

  //console.log(vault)
  

},[vaultReducer])

useEffect(() => {

  if (savesuccess) {
      successMessage('Request Loogged Successful')

      playOn()

      dispatch({ type: APPROVAL_MESSAGE_SAVE_REST })

      dispatch(messageListAction(userInfo.username))

  }


}, [save_Message_Reducer])



const makedeposi=()=>{

try {

  if(CustomerAccountDetails.length <=0){

    ErrorMessage('No Account Information Found')

  }else if(depositSeting.deposit=='No') {

    ErrorMessage('You have No Permission To Make Deposit')
  
  }else if(Number(depositSeting.maxdeposit) < amount) {

    ErrorMessage('You have No Permission To Deposit This Amount Of Cash.Your Maximum Deposit Limit is GH₵  ' +  numberFormat(depositSeting.maxdeposit).split('$')[1])

  }else if(CustomerAccountDetails.initial > amount) {

    ErrorMessage('Initial Deposit Required (IDR) GH₵ '+CustomerAccountDetails.initial+' Or More')

  }else if(!(tel.match('^[0-9]{10}$'))){

    ErrorMessage('Please provide valid Telephone Number')

  }else if(depoby==''){

    ErrorMessage('Who is Making This Withdrawal ?')

  }else if(ty==''){

    ErrorMessage('Please Provide Identification card')

  }else if(tyid==''){

  ErrorMessage('Please Provide Identification card Number')
  }else if(amount==''){

    ErrorMessage('Enter Deposit Amount')


  }else {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
      confirmButton: "btn btn-success mx-4",
      cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
      title: "Confirm",
      text: "Are You Sure You Want to Deposit The Entered Transaction  ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
   

  }).then((result) => {

      if (result.isConfirmed) {

        let initial=0
        let account_balance=amount
        
        
        let last_trans_Date=moment().format('MMMM Do YYYY, h:mm:ss a')
        let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
        
        
        let account_name=CustomerAccountDetails.account_name
        let account_number=CustomerAccountDetails.account_number
        let product_name=CustomerAccountDetails.product_name
       
        let cr=amount
        let dr=0.00
        let branch =CustomerAccountDetails.branch
        let des ='Deposit'
        let dat2 =moment().format('YYYY-MM-DD')
        let user=userInfo.username
        let vault_id=PendingTreasur.trans_code
        

      dispatch(DepositAccounAction({trans_code:CustomerAccountDetails.trans_code ,
        initial ,
        account_balance,
        last_trans_Date
        }))
  
     dispatch(SaveAccountTransaction(cr ,dat ,amount ,dr ,branch ,account_number,des ,dat2 ,account_name,product_name ,user ,depoby,tel,ty,tyid,vault_id ,n200 , n100 ,n50 ,n20 ,n10 ,n5 ,n2 ,n1, c2 , c1 ,c05 ,c02 , c01 ,amountToWord))
    
     setrealDeposit(true)
     getTreasure()
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {

     
      }
      
  });

  }

  
} catch (error) {
  
}

}


const historyColumns =[
 
  {
   name:'#' ,
   selector:row=>row.trans_code ,
   width: "70px" 
   ,wrap:true 

  } ,

   {
    name:'Date' ,
    selector:row=>row.dat,
    width: "300px" 
    ,wrap:true
   },

   {
    name:'Deposit',
    selector:row=> cash(row.cr) ,
    width: "150px" 
    ,wrap:true ,
    sortable:true
   } ,

   {
    name:'Withdrawal',
    selector:row=> cash(row.dr) ,
    width: "150px" 
    ,wrap:true ,
    sortable:true
   },

   {
    name:'Transaction By',
    selector:row=> row.trans_by ,
    width: "300px" 
    ,wrap:true ,
    sortable:true
   },

   {
    name:'Tel#',
    selector:row=> row.tel ,
    width: "150px" 
    ,wrap:true ,
    sortable:true
   },
 
   {
    name:'User',
    selector:row=> row.user ,
    width: "200px" 
    ,wrap:true ,
    sortable:true
   },

   {
    name:'Receipt' , cell:row=> <button onClick={()=>receipt(row)} className='btt'><IoReceiptOutline className='preview-btn'/></button> ,
    width:"150px"
   } ,

   {

    name:'Veiw' , cell:row=> <button onClick={()=>gettransDetails(row)}  className='btt'> {(row.des=='Deposit' || row.des=='Withdrawal') && <MdEdit className='preview-btn'/> }</button> ,
    width:"70px"

   } ,
]

const receipt=(row)=>{

  settransactionId(row.trans_code)
  //console.log(transactionInfo.trans_code)
  setshowrec(true)

}

const [onePeriod ,setonePeriod]=useState()
const [twoPeriod ,settwoPeriod]=useState()

const [searchHistory ,setsearchHistory]=useState()


const UserTreasureBalances=()=>{

  window.open(`http://localhost/report/Taller_Account_balances.html?user=${userInfo.username}&dat_1=${dat2}&dat_2=${dat2}&branch=${userProfileInfo.branch}`, "_blank");
} 

const [transDetails ,showtransDetails]=useState()

const closetransDetails =()=>{
  showtransDetails(false)
  openShowHistory()
}

const opentransDetails =()=>{
  showtransDetails(true)
}

const [transdetailsx ,showtransdetailsx] = useState()

const gettransDetails=(row)=>{
  opentransDetails()
  showtransdetailsx(row)

  closeShowHistory()
}

const [whyMessageRequst, setwhyMessageRequst] = useState('')
const [prousers, setprousers] = useState([])
const [approval_by ,setapproval_by]=useState('')

const [rev ,showres]=useState()

const closeRequest=() => {
  showres(false)
  setwhyMessageRequst('')
  setapproval_by('')

}
const openRequest=() => {
  showres(true)
 
}


const [mobass ,setmobass]=useState()

const closemobass=() => {
  setmobass(false)
 

}
const openmobass=() => {
  setmobass(true)
  dispatch(MobileBankAccessLoad(dat2))
 
}

const [mobTransaction ,setmobTransaction]=useState()

const closemobTransaction=() => {
  setmobTransaction(false)
 

}
const openmobTransaction=() => {
  setmobTransaction(true)
 
}

const ALLUserReducer = useSelector(state => state.ALLUserReducer)
    const { AllUser, UserLoading, UserSuccess } = ALLUserReducer

const [mobileBanks ,setmobileBanks]=useState([])

useEffect(() => {

      setprousers(AllUser.filter(obj => (obj.loginstatus == 'System Administrator' || obj.loginstatus == 'Branch Manager' || obj.loginstatus == 'Operations Manager'
          || obj.loginstatus == 'General Manager' || obj.loginstatus == 'Bank Administrator') && obj.username != userInfo.username))

      setmobileBanks(AllUser.filter(obj => obj.loginstatus == 'Mobile Banker'))

      clear()

  }, [dispatch])


const RequestReversal=()=>{

  if(whyMessageRequst==''){
    
    ErrorMessage('Please Select Transaction Error')
 
  }else if(approval_by==''){

    ErrorMessage('Please Select Authorization User')

  }else {

    const apm = message.filter(obj => obj.table == 'account_transaction' && obj.approval_status == false && obj.table_pk == transdetailsx.trans_code && obj.message == 'Request Transaction Reversal')
    console.log(apm)

    if (apm.length > 0) {

      ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

  } else {

    let table = 'account_transaction'
    let action = 6
    let sent_by = userInfo.username
    let table_pk =transdetailsx.trans_code;
    let picc = userProfileInfo.image
    let dat = moment().format('YYYY-MM-DD')
    let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
    let title = transdetailsx.des
    let message_sum = whyMessageRequst
    let name = transdetailsx.account_name
    let accnumber = transdetailsx.account_number
    let RequestMessage = 'Request Transaction Reversal'
    let acc_ty=transdetailsx.product_name
    let tel=transdetailsx.amount

    //console.log(table_pk)
    //console.log(picc)
    //console.log(picc)
    //console.log(name)
    //console.log(message_sum)
    //console.log(tel)


    dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, picc, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, tel, '-', ''))
    
    closeRequest()
  }
}

}


const mobSave=()=>{

  if(mobUser==''){
    ErrorMessage('Select Users')
  }else if(mobmax=='' || Number(mobmax) <=0){
    ErrorMessage('Enter a valid maximum total deposit')

  }else {

    const getMob=mobCollToday.filter(obj=>obj.dat2==dat2 && obj.Access_User==mobUser)

    if(getMob.length >0){
      ErrorMessage(mobUser + ' Already Have an Activate Account')
    }else {
     
      let user =userInfo.username
      dispatch(MobileBankAccess(dat , dat2 , mobUser , user , mobmax))

    }
    


  }
}


const [search_dat ,setsearch_dat]=useState(dat2)

const searchmonAcc =()=>{
  
  dispatch(MobileBankAccessLoad(moment().format(search_dat)))
}

const MobileBankersAccounts =[
 
  {
   name:'#' ,
   selector:row=>row.trans_code ,
   width: "60px" 
   ,wrap:true 

  } ,

   {
    name:'Date' ,
    selector:row=>row.dat,
    width: "180px" 
    ,wrap:true
   },

   {
    name:'User',
    selector:row=> row.Access_User,
    width: "115px" 
    ,wrap:true ,
    sortable:true
   } ,

   {
    name:'Teller',
    selector:row=> row.Access_by ,
    width: "115px" 
    ,wrap:true ,
    sortable:true
   },

   {
    name:'Maximum Deposit',
    selector:row=> row.max_deposit ,
    width: "130px" 
    ,wrap:true ,
    sortable:true
   },

   {
    name:'Status',
    selector:row=> row.status == 1 ? 'Account Activate..': 'Account Close' ,
    width: "130px" 
    ,wrap:true ,
    sortable:true
   },

     
]

const MobileBankersTransactionColum =[
 
  {
   name:'Mobile Banker' ,
   selector:row=>row.Access_User ,
   width: "130px" 
   ,wrap:true 

  } ,

   {
    name:'Teller' ,
    selector:row=>row.Access_by,
    width: "110px" 
    ,wrap:true
   },

   {
    name:'Date',
    selector:row=> row.dat,
    width: "120px" 
    ,wrap:true ,
    sortable:true
   } ,

   {
    name:'Maximum Deposit',
    selector:row=> cash(row.max_deposit) ,
    width: "150px" 
    ,wrap:true ,
    sortable:true
   },

   {
    name:'Status',
    selector:row=> row.status == 1 ? 'Account Activate..': 'Account Close' ,
    width: "100px" 
    ,wrap:true ,
    sortable:true
   },

   {
    name:'View' , cell:row=> <div className='row '>
     <div className='col-md-6'><button onClick={()=>gettransaction(row)}   className='edit-btn'><FaRegEdit size={17} color="#0000A3" /> </button> </div>
    </div>,
   width:"80px"
  }   
]

const getMOBAcc=(row)=>{
  setmobUser(row.Access_User)
  setmobmax(row.max_deposit)
}


const gettransaction=(row)=>{
  dispatch(MOBAccountTransactionAction(row.dat2 ,row.Access_User))
  setmobbanker(row.Access_User)
  setTeller(row.Access_by)


  var  sum = 0;
  for(let i = 0; i < MOBAccount.length; i++){
  sum += MOBAccount[i].cr
}

settotalDeposit(sum)

settransDate(row.dat)

setStatus(row.status == 1 ? 'Account Activate..': 'Account Close')
}

useEffect(()=>{

  var  sum = 0;
  for(let i = 0; i < MOBAccount.length; i++){
  sum += MOBAccount[i].cr
}

settotalDeposit(sum)
setTransactionCount(MOBAccount.length)

},[MOBAccountTransactionReducer])




const tableColumn =[

  {
   name:'Account Number' ,
   selector:row=>row.	account_number,
   width: "180px" 
   ,wrap:true
  },

  {
   name:'Account Name' ,
   selector:row=>row.account_name,
   width: "190px" 
   ,wrap:true
  },

  {
    name:'Product Type' ,
    selector:row=>row.product_name,
    width: "180px" 
    ,wrap:true
   },

   ,
  {
   name:'Amount' ,
   selector:row=>  cash(row.amount),
   width: "100px" 
   ,wrap:true
  },

  ,
  {
   name:'Deposit By' ,
   selector:row=>row.trans_by,
   width: "150px" 
   ,wrap:true
  },

  {
    name:'Status',
    selector:row=> row.Status == 1 ? <IoCheckmark size={25} color="#008000" />: <FaXmark  size={17} color="Red" /> ,
    width: "80px" 
    ,wrap:true ,
    sortable:true
   },

  
  {
    name:'Action' , cell:row=> <div className='row '>
    <div className='col-md-6'><button  className='edit-btn'><FaRegEdit size={17} color="#0000A3" /> </button> </div>
    </div>,
    width:"80px"
     
  } 
] 


const MOBdeposite=()=>{

  let user=userInfo.username
  let vault_id=PendingTreasur.trans_code
  let Status=1

  for(let i = 0; i < MOBAccount.length; i++){
    
    if(MOBAccount[i].Status==0){
      console.log(MOBAccount[i])
      let trans_code= MOBAccount[i].trans_code
      
      dispatch(SaveAccountTransaction(MOBAccount[i].cr , MOBAccount[i].dat ,MOBAccount[i].amount ,MOBAccount[i].dr ,MOBAccount[i].branch 
        ,MOBAccount[i].account_number,MOBAccount[i].des ,MOBAccount[i].dat2 ,MOBAccount[i].account_name,MOBAccount[i].product_name ,user ,MOBAccount[i].trans_by,MOBAccount[i].tel,
        MOBAccount[i].idtype ,MOBAccount[i].idnumber,vault_id ,MOBAccount[i].n200 , MOBAccount[i].n100 ,MOBAccount[i].n50 ,MOBAccount[i].n20 ,MOBAccount[i].n10 ,MOBAccount[i].n5 
        ,MOBAccount[i].n2 ,MOBAccount[i].n1, MOBAccount[i].c2 , MOBAccount[i].c1 ,MOBAccount[i].c05 ,MOBAccount[i].c02 , MOBAccount[i].c01 ,MOBAccount[i].amount_word))
     
      dispatch(MOBTransactionUpdateAction({trans_code:trans_code,Status}))

      setmobDeposit(true)

    }

   
  }
}

  return (
    <div>


<h3>Customer Account Deposit</h3>
        <hr/>
  
        <div className='solcon overflow-auto vh-100'>
        <div className='container'>

        {CustomerAccountDetailsLoading && <Loader/>}

        {accountbalanceLoading && <Loader/>}

        {UserTreasureTodayLoading  && <Loader/>}

        {userbalanceLoading  && <Loader/>}

        {TreasureTodayLoading  && <Loader/>}

        {TreasureTodaySettingLoading  && <Loader/>}

        {saveVaultTransactionLoading  && <Loader/>}

        {CorporateAccountsignatoryDetailsLoading  && <Loader/>}

        {accountAutoServicerLoading  && <Loader/>}

        {updateUserVaultLoading  && <Loader/>}

        {Account_histoeyLoading  && <Loader/>}
        

            <div className='row table_buttom_margin'>

            <div className='col-md-3'> 
                    <div className='card mb-4'>

                        <div className='card-header userbal d-flex flex-column align-items-center'>Account Infomation</div>
                        <div className='section profile'>

                        <div className="card">

                          <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">

                          <header className="App-header mb-3 mt-3">
                           
                           
                           <div style={{ width: 250 }}>
                              <ReactSearchAutocomplete
                              items={cAccNo}
                              // fuseOptions={{ keys: ["trans_code", "tel"] }}
                              // resultStringKeyName="tel"
                              
                              onSearch={handleOnSearch}
                              onHover={handleOnHover}
                              onSelect={handleOnSelect}
                              onFocus={handleOnFocus}
                              onClear={clear}
                              autoFocus
                              
                              formatResult={formatResult}
                              placeholder='Search Account Number'
                              
                              />
                            </div>
                           
                           
                            
                          </header>

                            {
                          
                              CustomerAccountDetails.length <=0 ? <FaUserTie size={100}/>
                              
                              :  
                              CustomerAccountDetails.picone=='' ? <FaUserTie size={100}/>  
                               
                              :  <img  width={100} height={100} src={CustomerAccountDetails.picone} className="rounded-circle"/> 
                              
                              
                            }
                            
                          
                            <p className='mt-4' >{ CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx' :CustomerAccountDetails.account_number}</p>
                            <p >{CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx':CustomerAccountDetails.account_name}</p>
                            <p >{CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx':CustomerAccountDetails.product_name}</p>
                            <p >{CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx' :CustomerAccountDetails.branch}</p>
                            <p>{CustomerAccountDetails.length <=0 ? 'xxxxxxxxxxxxx' :CustomerAccountDetails.acc_type}</p>


                            {
                                CustomerAccountDetails.acc_type=='Individual Account' ? 
                                 <button onClick={handleopenModal}  className="show_info_btn">Deposit Form</button>

                                 : 

                                 CustomerAccountDetails.acc_type=='Corporate Account' ?  
                                 
                                 <button onClick={handleopenModalcor}  className="show_info_btn">Deposit Form</button>

                                 :
                                 ''
                              }

                              
                              {CustomerAccountDetails.length <=0 ? ''
                              :
                               <button onClick={openShowHistory}  className="mt-3 show_info_btn">Account History</button>}

                               
                           
                          </div>
                        </div>

                        
                        </div>


                    </div>
           
            </div>

            <div className='col-md-5'>
                <div className='card'>
                    <div className='card-header userbal  d-flex flex-column align-items-center'>Deposit Information</div>

                    <div className="card">

                    <div className='card-header'><FcMoneyTransfer/> Account Balance</div>
                        <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">    
                            
                             <h3 className='mt-2'>GH₵ {cash(accountbalance.length <=0 ? '0.00' :accountbalance[0].account_balance)}</h3> 
                             
                        </div>
                        </div>

                        <div className="card-body profile-card pt-3 "> 

                        <div className='row'>
                              <div className="col-md-6 col-lg-6 mt-3">
                                 
                                 <lable className='lable'  for='Login Status'><samp className='text-danger'>* </samp> Deposit By</lable>
                                 <input disabled name="Name" required type="text"  className="form-control text-center "  value={depoby}  />
                             </div> 

                             <div className="col-md-6 col-lg-6 mt-3">
                                 
                                 <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp>Telephone Number</lable>
                                 <input disabled name="Name" required type="text"  className="form-control text-center " value={tel} />
                             </div> 
                              </div>

                              <div className='row'>
                              <div className="col-md-6 col-lg-6 mt-3">
                                 
                                 <lable className='lable'  for='Login Status'><samp className='text-danger'>* </samp> Identification Card</lable>
                                 <input disabled name="Name" required type="text"  className="form-control text-center " value={ty} />
                             </div> 

                             <div className="col-md-6 col-lg-6 mt-3">
                                 
                                 <lable className='lable'  for='Login Status'><samp className='text-danger'>* </samp>Identification Number</lable>
                                 <input disabled name="Name" required type="text"  className="form-control text-center " value={tyid} />
                             </div> 
                              </div>


                            <div className='row'>
                            <div className="col-md-6 col-lg-6 mt-3">
                               
                                <lable  for='Login Status'><samp className='text-danger'>* </samp> Deposit Amount</lable>
                                

                                <CurrencyInput
                                id="input-example"
                                name="input-name"
                                //placeholder="Please enter a number"
                                defaultValue={0}
                                decimalsLimit={2}
                                className='form-control text-center'
                                value={amount}
                                decimalScale={2}
                                
                                />


                            </div>

                            <div className="col-md-6 col-lg-6 mt-3">
                               
                            <button onClick={cashDenomination} className="show_info_btn mt-3">Deposit Denomination</button>
                            </div>
                            </div>

                            <div className="col-md-12 col-lg-12 mt-3">
                               
                                <lable   for='Login Status'><samp className='text-danger'>* </samp> Amount In Words</lable>
                                <input disabled name="Name" required type="text" value={amountToWord} className="form-control text-center" />
                            </div>  

                </div>

                {transactionLoading  && <Loader/>}

                {
                
                upat  ?

                UserTreasureToday.length <=0 ? '' : 

                transactionLoading ? '' :
                
                PendingTreasur ? PendingTreasur.close_status== 1 || PendingTreasur.save_aproval== 0  ? '': 
                
                <button onClick={makedeposi}  className="btn-class mt-3">Make Deposit</button>

                
                : <button onClick={makedeposi}  className="btn-class mt-3">Make Deposit</button>

                 :''
                }
               
                </div>

            </div>

            <div className='col-md-4'>  

            { CustomerAccountDetails.length <=0 ? '' 
            :
            CustomerAccountDetails.initial !=0 ? (
              <div className='card mb-4'>
              <div className='card-header userbal  d-flex flex-column align-items-center'>Other Account Infomation (* NOTE!)</div>
              <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">
                <samp className='text-danger'>* Initial Deposit Required</samp>

                <samp className='text-danger'> GH₵ { CustomerAccountDetails.initial} Or More</samp>
                
              </div>
              </div>
            ): (
              
              <div className='card mb-2'>
              <div className='card-header userbal  d-flex flex-column align-items-center'>Customer Last Transaction</div>
              <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">
               
                 <samp>{ CustomerAccountDetails.last_trans_Date}</samp>
                
                <samp > GH₵ {cash(CustomerAccountDetails.account_balance)} </samp>
                
                
              </div>

             
              </div>

            )}

              


            <div className='card'>

              <div className='card-header userbal  d-flex flex-column align-items-center'>User Treasure Balances Today </div>

              <div className="card-body profile-card d-flex flex-column align-items-center">    
                            
                  <h5>{moment().format('MMMM Do YYYY')}</h5> 
                            
                </div>

                <div className='card-body'>

                
                {upat  ? 

                PendingTreasur ? 
                
                PendingTreasur.save_aproval==1 && showTreasurBal ? '' 
                
                :
                
                (
                  <div className='row mb-3'>
                  <div className='col-md-4'> <samp className='text-danger' >Vault:</samp> </div>
                  <div className='col-md-6'> <samp className='text-danger' >GH₵ {cash(numberFormat(PendingTreasur.dr))}</samp> </div>
                 
                  <div className='col-md-1'> <button onClick={()=>showUserTreasuer(PendingTreasur)} className='btt'> <FcCheckmark className='preview-btn'/> </button></div>
                
                </div>
                 )
                
                 : 
                  ''
                
                :''
                
                 }
              

                <div className='row'>

                    <div className='col-md-4'>
                    <samp >Treasure:</samp> 
                    </div>

                    <div className='col-md-8'>
                    <samp > GH₵ {PendingTreasur &&  PendingTreasur.save_aproval ?   cash(PendingTreasur.dr):'0.00'} </samp> 
                    </div>
                  </div>

                  <hr/>

                <div className='row'>
                    <div className='col-md-4'>
                    <samp >Deposit:</samp> 
                    </div>

                    <div className='col-md-8'>
                    <samp >GH₵  {numberFormat(deposit).split('$')[1]} </samp> 
                    </div>
                  </div>

                  <hr/>
                  <div className='row'>
                    <div className='col-md-4'>
                    <samp >Withdrawal:</samp> 
                    </div>

                    <div className='col-md-8'>
                    <samp >GH₵ {numberFormat(withdrawal).split('$')[1]}</samp>
                    </div>
                  </div>
                  <hr/>

                  <div className='row'>
                    <div className='col-md-4'>
                    <samp >Balance:</samp> 
                    </div>

                    <div className='col-md-8'>
                    <samp >GH₵  {numberFormat(userBala).split('$')[1]} </samp> 
                    </div>
                  </div>

                  <div className=' row p-2' >
                  <button onClick={UserTreasureBalances}  className="show_info_btn">Transaction Report</button>
                  
                  </div>

                  <div className=' row p-2' >

                  {upat  ? 

                      PendingTreasur ? 

                      PendingTreasur.save_aproval==1 &&  PendingTreasur.close_status==0 ?  
                     (
                      <>

                      <button  onClick={openmobass}  className="show_info_btn mb-3">Mobile Banker Access</button> 
                      <button  onClick={openmobTransaction}  className="show_info_btn mb-3">Mobile Banker Transaction </button> 
                      <button  onClick={()=>userCloseVault(PendingTreasur.trans_code)}  className="show_info_btn"> Close Treasure Balances</button>

                      </>
                     ) 
                      
                    
                      :

                      PendingTreasur.close_status==1  &&  PendingTreasur.save_aproval==1  ? ''
                      
                      :
                      //<button  onClick={()=>userCloseVault(PendingTreasur.trans_code)}  className="show_info_btn"> Close Treasure Balances</button>
                      ''
                      : 
                        ''

                      :''

                      }
                      
                       
                        </div>

                  </div>
              </div>
            </div>
        </div>
        </div>
       </div>

       
    <Modal size="lg" show={show} onHide={handleCloseModal}  backdrop="static" keyboard={false}>                                                                                                                                 
        
        <Modal.Header closeButton>
        <Modal.Title>Account / Verification Form</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        
        <h5 className='mb-3'>Account Signatory's Information</h5>
        <div className='row'>

            <div className='col-md-3'>

                <samp><samp className='text-danger'>* </samp> Picture</samp>

                {
                ! CustomerAccountDetails.picone ? 
                
                <FaUserLarge  size={120} className="p-2 mb-3 card-img-top embed-responsive-item"/> 
                                 
                :<img  className="card-img-top embed-responsive-ite card-img-top embed-responsive-item" width="100" height="120" src={CustomerAccountDetails.picone} />
  
                }

                <samp><samp className='text-danger'>* </samp> Signature</samp>
                 

                {
                ! CustomerAccountDetails.signature ? 
                
                <PiSignatureThin  size={120} className="card p-2 mb-3"/> 
                                 
                : <img  className="card-img-top embed-responsive-ite card-img-top embed-responsive-item" width="100" height="120" src={CustomerAccountDetails.signature} />
  
                }
                   
            </div>



            <div className='col-md-8'>

                <div className='card mt-4'>
                <div className='card-body'>

                <div className="row mb-2">
                <div className="col-lg-6 col-md-6 label">Account Number:</div>
                <div className="col-lg-6 col-md-6">{CustomerAccountDetails.account_number }</div>
                </div>

                <div className="row mb-2">
                <div className="col-lg-6 col-md-6 label">Account Name:</div>
                <div className="col-lg-6 col-md-6">{CustomerAccountDetails.account_name }</div>
                </div>

                <div className="row mb-2">
                <div className="col-lg-6 col-md-6 label">Account Type:</div>
                <div className="col-lg-6 col-md-6">{CustomerAccountDetails.product_name }</div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-6 col-md-6 label">Telephone Number:</div>
                <div className="col-lg-6 col-md-6">{CustomerAccountDetails.tel1}</div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-6 col-md-6 label">Identification:</div>
                <div className="col-lg-6 col-md-6">{CustomerAccountDetails.card_ty}</div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-6 col-md-6 label">Identification Number:</div>
                <div className="col-lg-6 col-md-6">{CustomerAccountDetails.card_Id}</div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 label">Branch:</div>
                <div className="col-lg-6 col-md-6">{CustomerAccountDetails.branch }</div>
              </div>

              </div>

              <button onClick={getholderInfo} className="show_info_btn p-1 m-1">Deposit By Account Holder</button>

                </div>

                
                
            </div>
        </div>

        <h5 className='mt-3 mb-3'>Deposit Form Infomation</h5>

            <div className='row'>
            <div className="col-md-6 col-lg-6 mb-2">
                <label className='lable' for='Surname'><samp className='text-danger'>* </samp> Deposit By</label>
                <input name="Name"  required type="text" className="form-control"  value={depoby} onChange={(e)=>setdepoby(e.target.value)} />
            </div>
            
            <div className="col-md-6 col-lg-6 mb-2">
                <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Telephone Number </label>
                <input name="Name"  required type="number" className="form-control" value={tel} onChange={(e)=>settel(e.target.value)} />
            </div>
           
            </div>


            <div className='row'>
            <div className="col-md-6 col-lg-6">
                <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Type Of Identification</lable>
                <select required  className='form-control'  value={ty} onChange={(e)=>setty(e.target.value)} >
                    <option selected value="Select" >Select</option>
                    <option value="Voters Card">Voters Card</option>
                    <option value="Ghana Card">Ghana Card</option>
                    <option value="Drivers License">Drivers License</option>
                    <option value="Passport">Passport</option>
                    <option value="SSNIT Card">SSNIT Card</option>
                </select>
            </div>
            
            <div className="col-md-6 col-lg-6">
                <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Identification Number </label>
                <input name="Name"  required type="text" className="form-control" value={tyid} onChange={(e)=>settyid(e.target.value)} />
            </div>
           

            </div>
            
                <button onClick={handleCloseModal} className="btn-class mt-3">Account / Deposit Verify</button>
           
            

        </Modal.Body>
                   
    </Modal>


    <Modal size="lg" show={showcor} onHide={handleCloseModalcor} dialogClassName="my-modal-small"  backdrop="static" keyboard={false}>                                                                                                                                 
        
        <Modal.Header closeButton>
        <Modal.Title>Deposit / Verification Form</Modal.Title>
        </Modal.Header>
        <Modal.Body >

          <div className='row'>


          <div className='col-md-4'>
            <div className="card">
            <div className='card-header'>Account Signatory's (1)</div>
            <div className="card-body profile-card"> 
              <div className='row'>
                <div className='col-md-6'>
                   <samp>Picture</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {CustomerAccountDetails.picone ? ( <img  className="card-img-top embed-responsive-item" width="100" height="100"  src={CustomerAccountDetails.picone} /> ) 
                        : (<img   className="card-img-top embed-responsive-item" width="100" height="100" src={profile} />)}
                  </div>
                </div>
                
                
                </div>
                <div className='col-md-6'>
                <samp>Signature</samp>
                   <div className="card mt-2">
                    
                   <div className="embed-responsive embed-responsive-16by9">
                        {CustomerAccountDetails.signature  ? ( <img  className="card-img-top embed-responsive-item" width="100" height="100" src={CustomerAccountDetails.signature} /> ) 
                        : (<img  className="card-img-top embed-responsive-item"  width="100" height="100" src={sigd} />)}
                  </div>
                </div>
               
                </div>

                <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">
                
                <div className='mt-2'>{CustomerAccountDetails.na1}</div>
                <div className='mt-2'>{CustomerAccountDetails.tel1}</div>
                <div className='mt-2'>{CustomerAccountDetails.card_ty}</div>
                <div className='mt-2'>{CustomerAccountDetails.card_Id}</div>

                <button onClick={getsig1Information} className="show_info_btn m-1 mt-3">Depositor</button>

                </div>
                
              </div>
            </div> 
            </div>
          </div>

          <div className='col-md-4'>
            <div className="card">
            <div className='card-header'>Account Signatory's (2)</div>
            <div className="card-body profile-card"> 
              <div className='row'>
                <div className='col-md-6'>
                   <samp>Picture</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {CustomerAccountDetails.pictwo  ? ( <img  className="card-img-top embed-responsive-item" width="100" height="100"  src={CustomerAccountDetails.pictwo} /> ) 
                        : (<img   className="card-img-top embed-responsive-item" width="100" height="100" src={profile} />)}
                  </div>
                </div>
                
                
                </div>
                <div className='col-md-6'>
                <samp>Signature</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {CustomerAccountDetails.signature2  ? ( <img  className="card-img-top embed-responsive-item" width="100" height="100" src={CustomerAccountDetails.signature2} /> ) 
                        : (<img   className="card-img-top embed-responsive-item"  width="100" height="100" src={sigd} />)}
                  </div>
                </div>
               
                </div>
                
              </div>

              <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">
                
                <div className='mt-2'>{CustomerAccountDetails.tel2}</div>
                <div className='mt-2'>{CustomerAccountDetails.na2}</div>
                <div className='mt-2'>{CustomerAccountDetails.card_ty_2}</div>
                <div className='mt-2'>{CustomerAccountDetails.card_Id_2}</div>

                <button onClick={getsig2Information} className="show_info_btn m-1 mt-3">Depositor</button>

                </div>
            </div> 
            </div>
            </div>


            <div className='col-md-4'>
            <div className="card">
            <div className='card-header'>Account Signatory's (3)</div>
            <div className="card-body profile-card"> 
              <div className='row'>
                <div className='col-md-6'>
                   <samp>Picture</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {CustomerAccountDetails.picthree  ? ( <img  className="card-img-top embed-responsive-item" width="100" height="100"  src={CustomerAccountDetails.picthree} /> ) 
                        : (<img   className="card-img-top embed-responsive-item" width="100" height="100" src={profile} />)}
                  </div>
                </div>
                
                
                </div>
                <div className='col-md-6'>
                <samp>Signature</samp>
                   <div className="card mt-2">
                   <div className="embed-responsive embed-responsive-16by9">
                        {CustomerAccountDetails.signature3 ? ( <img  className="card-img-top embed-responsive-item" width="100" height="100" src={CustomerAccountDetails.signature3} /> ) 
                        : (<img  className="card-img-top embed-responsive-item"  width="100" height="100" src={sigd} />)}
                  </div>
                </div>
               
                </div>
                
              </div>

              <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">
                
                <div className='mt-2'>  {CustomerAccountDetails.tel3 =='' ? 'xxxxxxxxxxxxxxxxx': CustomerAccountDetails.tel3 }</div>
                <div className='mt-2'>{CustomerAccountDetails.na3 =='' ? 'xxxxxxxxxxxxxxxxx':  CustomerAccountDetails.na3}</div>
                <div className='mt-2'>{CustomerAccountDetails.card_ty_3=='' ? 'xxxxxxxxxxxxxxxxx':  CustomerAccountDetails.card_ty_3}</div>
                <div className='mt-2'>{CustomerAccountDetails.card_Id_3=='' ? 'xxxxxxxxxxxxxxxxx':  CustomerAccountDetails.card_Id_3}</div>

                <button  onClick={getsig3Information}  className="show_info_btn m-1 mt-3">Depositor</button>

                </div>

               
            </div> 


            </div>
            </div>

          </div>


          <h5 className='mt-3 mb-3'>Deposit Form Infomation</h5>

          <div className='row'>
          <div className="col-md-6 col-lg-6 mb-2">
              <label className='lable' for='Surname'><samp className='text-danger'>* </samp> Deposit By</label>
              <input name="Name"  required type="text" className="form-control"  value={depoby} onChange={(e)=>setdepoby(e.target.value)} />
          </div>

          <div className="col-md-6 col-lg-6 mb-2">
              <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Telephone Number </label>
              <input name="Name"  required type="number" className="form-control" value={tel} onChange={(e)=>settel(e.target.value)} />
          </div>

          </div>


          <div className='row'>
          <div className="col-md-6 col-lg-6">
              <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Type Of Identification</lable>
              <select required  className='form-control'  value={ty} onChange={(e)=>setty(e.target.value)} >
                  <option selected value="Select" >Select</option>
                  <option value="Voters Card">Voters Card</option>
                  <option value="Ghana Card">Ghana Card</option>
                  <option value="Drivers License">Drivers License</option>
                  <option value="Passport">Passport</option>
                  <option value="SSNIT Card">SSNIT Card</option>
              </select>
          </div>

          <div className="col-md-6 col-lg-6">
              <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Identification Number </label>
              <input name="Name"  required type="text" className="form-control" value={tyid} onChange={(e)=>settyid(e.target.value)} />
          </div>


          </div>

          <button onClick={handleCloseModalcor} className="btn-class mt-3">Account / Deposit Verify</button>
        

        </Modal.Body>
                   
    </Modal>


    <Modal size="lg" show={showUnapproved} onHide={cloaseUnaprove}  backdrop="static" keyboard={false}>                                                                                                                                 
      <Modal.Header closeButton>
      <Modal.Title>Unapproved Treasure Out Amount </Modal.Title>
      </Modal.Header>
      <Modal.Body >

      
      <div className='row me-2'>


        <div className='col-md-12'>
          <div className='row'>

          {outDatedTr.map(Treasure=>(

        <div className='col-md-6 mb-6  '>
            <div className="card">
                <div className="card-body">
                <strong className="card-title">{Treasure.dat}</strong>
                <ul className="list-group">
                    <li className="list-group-item"><i></i> User : {Treasure.user}</li>
                    <li className="list-group-item"><i ></i> Vault: {Treasure.vault_name}</li>
                    <li className="list-group-item"><i ></i> Treasure Out: {cash(Treasure.amount)}</li>
                    <li className="list-group-item"><i ></i> Deposit: {cash(Treasure.deposit)}</li>
                    <li className="list-group-item"><i ></i>  Withdrawal: {cash(Treasure.withdrawal)}</li>
                    <li className="list-group-item"><i ></i> Balance: {cash(Treasure.close_bala)}</li>
                    
                      
                      <strong className="list-group-item"><i ></i><FcCheckmark size={20}/> Treasure Out Approved </strong>

                    {
                      Treasure.close_status==0 ? 

                      <strong className="list-group-item"><i ></i><FcHighPriority size={20}/> Treasure Close Balance Pending ... </strong>

                      :

                      <strong className="list-group-item"><i ></i><FcCheckmark size={20}/> Treasure Close Balance Approved </strong>

                    }
                      

                </ul>

                </div>
                
                {
                      Treasure.close_status==0 &&


                      <button onClick={()=>userCloseVault(Treasure.trans_code)}  className='show_info_btn'>Close Treasure Balance</button>

                     

                }

                 
              
                  
              
                
            </div>
          
        </div>

                        
        ))}

          </div>
          

        </div>

        

        </div>

      </Modal.Body>
                   
    </Modal>


    <Modal size="lg" show={showsearch} onHide={closersearch} dialogClassName="my-modal-small"  backdrop="static" keyboard={false}>                                                                                                                                 
            <Modal.Header closeButton>
            <Modal.Title>Denomination</Modal.Title>
            </Modal.Header>
            <Modal.Body  >

            <div className='card'>
              <div className='card-header userbal d-flex flex-column align-items-center'>Treasure Out</div>

              <div className='card-body ms-4 me-4'>
              <div className="card">
              <div className="card-body">
                <div className='row'>

                <div className='col-md-6 col-lg-6'>

                <div className='row'>
                  <div className='col-md-3 col-lg-3'>
                  
                      <h5 className="card-title  text-center ">Note</h5>
                      <hr/>
                      <input disabled name="number"  type="number" className="form-control  text-center" value={200} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={100} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={50} />
                      <input disabled  name="number"  type="number" className="form-control text-center" value={20}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={10}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={5}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={2}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={1}/>

                  </div>
                  <div className='col-md-5 col-lg-5'>
                  <h5 className="card-title text-center">Pieces </h5>
                  <hr/>
                      {getSaveDe ? <input value={n200}  name="number"  type="number" className="form-control text-center" />
                      : 
                      <input  value={n200}  onChange={(e)=>{
                         setn200(e.target.value)
                         if(e.target.value==''){
                          sett200(0)
                          
                         // setn200(0)
                        }else {

                          sett200((Number(e.target.value) * 200))
                         
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }

                      {getSaveDe ? <input  value={n100}  name="number"  type="number" className="form-control text-center" />
                      :
                     <input value={n100} onChange={(e)=>{
                         setn100(e.target.value)
                         if(e.target.value==''){
                          sett100(0)
                         // setn200(0)
                        }else {

                          sett100((Number(e.target.value) * 100))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }
                       {getSaveDe ? <input  value={n50}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n50} onChange={(e)=>{
                         setn50(e.target.value)
                         if(e.target.value==''){
                          sett50(0)
                         // setn200(0)
                        }else {

                          sett50((Number(e.target.value) * 50))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />

                    }
                      {getSaveDe ? <input  value={n20}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n20} onChange={(e)=>{
                         setn20(e.target.value)
                         if(e.target.value==''){
                          sett20(0)
                         // setn200(0)
                        }else {

                          sett20((Number(e.target.value) * 20))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                      {getSaveDe ? <input  value={n10}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n10} onChange={(e)=>{
                         setn10(e.target.value)
                         if(e.target.value==''){
                          sett10(0)
                         // setn200(0)
                        }else {

                          sett10((Number(e.target.value) * 10))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }
                       {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n5} onChange={(e)=>{
                         setn5(e.target.value)
                         if(e.target.value==''){
                          sett5(0)
                         // setn200(0)
                        }else {

                          sett5((Number(e.target.value) * 5))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                      {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n2} onChange={(e)=>{
                         setn2(e.target.value)
                         if(e.target.value==''){
                          sett2(0)
                         // setn200(0)
                        }else {

                          sett2((Number(e.target.value) * 2))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                    {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                    <input value={n1} onChange={(e)=>{
                         setn1(e.target.value)
                         if(e.target.value==''){
                          sett1(0)
                         // setn200(0)
                        }else {

                          sett1((Number(e.target.value) * 1))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />

                    }

                  </div>
                  <div className='col-md-4 col-lg-4'>
                  <h5 className="card-title text-center">Total</h5>
                  <hr/>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    fixedDecimalLength={2}
                    className='form-control text-center'
                    value={t200}
                    decimalScale={2}
                    
                    />
                    
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t100}
                    decimalScale={2}
                    
                    />
                    
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t50}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t20}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t10}
                    decimalScale={2}
                    
                    />
                     <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t5}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t2}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t1}
                    decimalScale={2}
                    
                    />

                  </div>
                  </div>
                  <hr/>
                  <div className='row'>
                    <div className='col-md-6'>
                    <h5 className="card-title text-center">Note Total GH₵:</h5>
                    </div>

                    <div className='col-md-6'>
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={notetotal}
                    decimalScale={2}
                    
                    />
                    </div>
                  </div>
                  </div>
                  
                  <div className='col-md-6 col-lg-6'>

                  <div className='row'>
                  <div className='col-md-3 col-lg-3'>
                  
                      <h5 className="card-title text-center">Coins</h5>
                      <hr/>
                      <input disabled name="number"  type="number" className="form-control  text-center" value={2} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={1} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={0.5} />
                      <input disabled  name="number"  type="number" className="form-control text-center" value={0.2}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={0.1}/>
                     
                      
                      
                     

                  </div>
                  <div className='col-md-5 col-lg-5'>
                  <h5 className="card-title text-center">Pieces</h5>
                  <hr/>
                  {getSaveDe ? <input  value={c2}  name="number"  type="number" className="form-control text-center" />
                    :
                  <input value={c2} onChange={(e)=>{
                         setc2(e.target.value)
                         if(e.target.value==''){
                          settc2(0)
                          
                         // setn200(0)
                        }else {

                          settc2((Number(e.target.value) * 2))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }
                      {getSaveDe ? <input  value={c1}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c1} onChange={(e)=>{
                         setc1(e.target.value)
                         if(e.target.value==''){
                          settc1(0)
                          
                         // setn200(0)
                        }else {

                          settc1((Number(e.target.value) * 1))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>

                    }
                     {getSaveDe ? <input  value={c05}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c05} onChange={(e)=>{
                         setc05(e.target.value)
                         if(e.target.value==''){
                          settc05(0)
                          
                         // setn200(0)
                        }else {

                          settc05((Number(e.target.value) * 0.5))
                         
                        
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }

                    {getSaveDe ? <input  value={c02}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c02} onChange={(e)=>{
                         set02(e.target.value)
                         if(e.target.value==''){
                          setc02(0)
                          
                         // setn200(0)
                        }else {

                          setc02((Number(e.target.value) * 0.2))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>

                    }

                    {getSaveDe ? <input  value={c01}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c01} onChange={(e)=>{
                         setc01(e.target.value)
                         if(e.target.value==''){
                          settcc01(0)
                          
                         // setn200(0)
                        }else {

                          settcc01((Number(e.target.value) * 0.1))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }
                  </div>
                  <div className='col-md-4 col-lg-4'>
                  <h5 className="card-title text-center ">Total</h5>
                  <hr/>
                  
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc2}
                    decimalScale={2}
                    
                    />
                 
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc1}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc05}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tcc02}
                    decimalScale={2}
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tcc01}
                    decimalScale={2}
                    />



                  </div>
                  </div>
                  <hr/>
                  <div className='row'>
                    <div className='col-md-6'>
                    <h5 className="card-title text-center">Coins Total GH₵:</h5>
                    </div>

                    <div className='col-md-6'>

                    <CurrencyInput
                    
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    fixedDecimalLength={2}
                    value={Coinstotal}
                    decimalScale={2}

                    />
                    </div>
                  </div>

                  <div className='mt-5'>
                    
                  </div>
                  </div>
                  
                </div> 
                </div>
                <hr/>
                <div className='row mb-3'>
                    <div className='col-md-3'>
                    <h5 className="card-title text-center">Grand Total GH₵:</h5>
                    </div>

                    <div className='col-md-2'>

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={gradeotal}
                    decimalScale={2}
                    
                    />
                    </div>


                    <div className='col-md-4'>

                   
                    <input value={amountToWord} name="Name" required type="text" className="form-control text-center" />
                    </div>

                    <div className='col-md-3'>

                    {getSaveDe ?  
                    
                   
                    <button  onClick={aprovalVault} className="show_info_btn">Confirm Treasure Balances</button>
                   
                    
                    : getSaveDe==false && cad ? 
                    
                    <button  onClick={totalcash} className="show_info_btn">OK</button>
                    
                    :''

                    }
                  </div>
                  </div> 

                 


                      

                </div>

              </div>

              </div>
                
            
            </Modal.Body>
                        
    </Modal>

    
    
    <Modal size="lg" show={showHistory} onHide={closeShowHistory} dialogClassName="my-modal"  backdrop="static" keyboard={false}>                                                                                                                                 
            <Modal.Header closeButton>
            <Modal.Title>Account History</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <div className='card'>
              <div className='card-header userbal d-flex flex-column align-items-center'>Customer Account Transaction</div>

              <div className='card-body ms-4 me-4'>
              <div className="card-body">
                     
                     <div className='row'>
                      <h5 className="card-title">Search Transaction By date</h5>
                      <div className="col-md-4 col-lg-3">
                        <label className='' for='Surname'>1st Period</label>
                        <input name="number"  type="date" className="form-control" value={onePeriod} onChange={(e)=>setonePeriod(e.target.value)} />
                      </div>

                      <div className="col-md-4 col-lg-3">
                        <label className='' for='Surname'>2nd Period</label>
                        <input name="number"  type="date" className="form-control"  value={twoPeriod} onChange={(e)=>settwoPeriod(e.target.value)} />
                      </div>

                      <div className="col-md-4 col-lg-4">
                       
                        <button className=' mt-3 show_info_btn'>Search Transaction</button>
                        
                      </div>

                      </div>

                      <hr/>
                      <div className='card'>

                      <DataTable className='rdt_Table' columns={historyColumns}  data={Account_histoey}   
                        selectableRowsHighlight 
                        highlightOnHover
                        fixedHeader
                        pagination 
                        paginationPerPage={8}
                          //onSelectedRowsChange={getSelectedSubjects}
                        // selectableRows  
                          //selectableRowSelected={getPatientInfo} 
                          //</div> onRowClicked={getPatientInfo}
                        responsive={true}
                        >
          
                      </DataTable>

                      </div>
                                            
                    </div>
              </div>
              </div>

            
            </Modal.Body>
                        
    </Modal>


    <Modal size="lg" show={transDetails} onHide={closetransDetails}  backdrop="static" keyboard={false}>                                                                                                                                 
            <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body  >

            <div className='card'>
              <div className='card-header userbal d-flex flex-column align-items-center'>Account Transaction Details</div>

              <div className='card-body ms-4 me-4'>
              <div className="card-body">
                     
                     <div className='row'>
                      <div className='col-md-6'>
                      <h5 className="card-title mb-3">Account Information</h5>
                      <p >{transdetailsx && transdetailsx.account_number}</p>
                      <p >{transdetailsx && transdetailsx.account_name}</p>
                      <p >{transdetailsx && transdetailsx.product_name}</p>
                      <p >{transdetailsx && transdetailsx.branch}</p>

                      </div>

                      <div className='col-md-6'>
                      <h5 className="card-title mb-3">Transaction By</h5>
                      <p >{transdetailsx && transdetailsx.trans_by}</p>
                      <p >{transdetailsx && transdetailsx.tel}</p>
                      <p >{transdetailsx && transdetailsx.idtype}</p>
                      <p >{transdetailsx && transdetailsx.idnumber}</p>

                      </div>
                      </div>
                      <div className='row mt-3'>
                      <div className='col-md-6'>
                      <h5 className="card-title mb-3">Transaction</h5>
                      <p >{transdetailsx && transdetailsx.dat}</p>
                      <p >{transdetailsx && transdetailsx.des}</p>
                      <p >{transdetailsx && cash(transdetailsx.amount)}</p>
                      <p >{transdetailsx && transdetailsx.amount_word}</p>
                     

                      </div>

                      <div className='col-md-6'>
                      <h5 className="card-title mb-3">Transaction Action</h5>
                      
                      <button onClick={openRequest} className="show_info_btn mb-4">Reverse Transaction</button>
                      <button className="show_info_btn">Transaction Receipt</button>

                      </div>

                      </div>

                      
                                            
                    </div>
              </div>
              </div>

            
            </Modal.Body>
                        
    </Modal>


    <Modal size="lg" show={rev} onHide={closeRequest} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Request Transaction Reversal</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                {messagesaveLoading && <Loader/>}

                {aproMessageLoading && <Loader/>}


                
                
                    <div className='row mb-3'>

                    <div className='col-md-6'>

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-3" value={transdetailsx && transdetailsx.dat} />

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Account Number</label>
                    <input name="Name" type="text" className="form-control mb-3" value={transdetailsx &&transdetailsx.account_number} />

                     <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Account Name</label>
                    <input name="Name" type="text" className="form-control mb-3" value={transdetailsx && transdetailsx.account_name} />


                     <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Product Name</label>
                    <input name="Name" type="text" className="form-control mb-3" value={transdetailsx && transdetailsx.product_name} />

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-3" value={dat} />

                    </div>



                     <div className='col-md-6'>

                      <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Transaction id</label>
                    <input name="Name" type="text" className="form-control mb-3" value={transdetailsx &&transdetailsx.trans_code} />

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Details</label>
                    <input name="Name" type="text" className="form-control mb-3" value={transdetailsx &&transdetailsx.des} />

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Amount</label>
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control mb-3'
                    value={transdetailsx &&transdetailsx.amount}
                    decimalScale={2}
                    
                    />


                     <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Transaction Error</label>
                    <select required className='form-control mb-3' value={whyMessageRequst} onChange={(e)=>setwhyMessageRequst(e.target.value)} >
                       
                        <option selected disabled value='' >Select</option>
                        <option value="Wrong Entry">Wrong Entry</option>
                        <option value="Double-Entry">Double-Entry</option>
                                        
                    </select>

                    
                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-3' value={approval_by} onChange={(e) => setapproval_by(e.target.value)}>
                        <option selected disabled value='' >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus}</option>
                        ))}

                    </select>

                      
                    </div>

                    </div>
                   
                    <button onClick={RequestReversal}  className="show_info_btn">Request Transaction Reversal</button>




                </Modal.Body>

    </Modal>



    <Modal size="lg" show={mobass} onHide={closemobass} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                <Modal.Title>Mobile Bankers Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                {creatUserSettingValutLoading  && <Loader/>}

                {mobCollTodayLoading  && <Loader/>}

                <div className='row'>
                <div className='col-md-6'>
                <label  for='Utility'><samp className='text-danger'>* </samp>Select Users</label>
                    <select required className='form-control mb-3' value={mobUser} onChange={(e) => setmobUser(e.target.value)}>
                        <option selected disabled value='' >Select</option>
                        {mobileBanks.map((users) => (
                            <option value={users.username}>{users.name}</option>
                        ))}

                    </select>

                    </div>

                    <div className='col-md-6'>
                    <label for='Utility'><samp className='text-danger'>* </samp>Maximum Total Deposit</label>
                    <input name="Name" type="number" className="form-control mb-3" value={mobmax} onChange={(e) => setmobmax(e.target.value)} />
                    </div>
                    </div>

                    <button onClick={mobSave}  className="show_info_btn">Grant Collection Access</button>

                    


                    <div className='card mt-3'>
                        

                        <DataTable className='rdt_Table' columns={MobileBankersAccounts}  data={mobCollToday=='' ? mobCollToday : mobCollToday}   
                            selectableRowsHighlight 
                            highlightOnHover
                            fixedHeader
                            pagination 
                            paginationPerPage={10}
                                //onSelectedRowsChange={getSelectedSubjects}
                           // selectableRows  
                                //selectableRowSelected={getPatientInfo} 
                                //</div> onRowClicked={getPatientInfo}
                            responsive={true}
                        >

                        </DataTable>

                        
                    </div>
               
                </Modal.Body>

    </Modal>


    <Modal size="lg" show={mobTransaction} onHide={closemobTransaction} backdrop="static" keyboard={false} dialogClassName="my-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Mobile Bankers Transaction</Modal.Title>
                    {MOBAccountLoading && <Loader/>}
                    {mobUpdateTrLoading && <Loader/>}
                    {transactionLoading  && <Loader/>}
                    
                </Modal.Header>
                <Modal.Body>

                  <div className='row'>
                    <div className='col-md-5'>
                      
                        <div className='card-body'>
                      <div className="row">
                          <h4 for="Email" className="mb-2">Search</h4>
                            
                              <div className="col-md-8 col-lg-8">
                              <div className='form-group mt-2'>
                                  <label for='username'>Date</label>
                                  <input type='Date' name='username' className='form-control' value={search_dat} onChange={(e)=>setsearch_dat(e.target.value)} />
                              </div>
                              </div>

                              <div className="col-md-4 col-lg-4">
                                  <button onClick={searchmonAcc} className="btn btn-primary mt-4 "><GoSearch/> Search</button>
                              </div>
                              
                          </div>
                      </div>
                      

                      <div className='card mt-3'>
                        
                        <DataTable className='rdt_Table' columns={MobileBankersTransactionColum}  data={mobCollToday=='' ? mobCollToday : mobCollToday}   
                            selectableRowsHighlight 
                            highlightOnHover
                            fixedHeader
                            pagination 
                            paginationPerPage={10}
                                //onSelectedRowsChange={getSelectedSubjects}
                           // selectableRows  
                                //selectableRowSelected={getPatientInfo} 
                                //</div> onRowClicked={getPatientInfo}
                            responsive={true}
                        >

                        </DataTable>

                        
                    </div>

                    </div>

                    <div className='col-md-7'>
                        <div className='row'>

                        <h4 for="Email" className="mb-2 mt-3">Mobile Banker Transaction</h4>

                       <div className='row'>
                          <div className='col-md-6'>

                          <label for='Utility'>Mobile Banker</label>
                          <input name="Name" type="text" className="form-control mb-3" value={mobbanker} />

                          <label for='Utility'>Date/Time</label>
                          <input name="Name" type="text" className="form-control mb-3" value={transDate} />

                          
                          <label for='Utility'>Teller</label>
                          <input name="Name" type="text" className="form-control mb-3" value={Teller} />

                           <button  className="show_info_btn mt-3 mb-2 "><TbReport/> Transaction Report</button>
                          </div>


                          <div className='col-md-6'>

                          <label for='Utility'>Total Deposit</label>
                          <input name="Name" type="text" className="form-control mb-3" value={numberFormat(totalDeposit).split('$')[1]} />

                          <label for='Utility'>Transaction Count</label>
                          <input name="Name" type="text" className="form-control mb-3" value={TransactionCount} />

                          
                          <label for='Utility'>Status</label>
                          <input name="Name" type="text" className="form-control mb-3" value={Status} />


                          <button onClick={MOBdeposite}  className="show_info_btn mt-3 mb-2 "><IoMdCheckmarkCircle /> Make Deposit</button>
                          
                           
                          </div>

                          
                       </div>
                          
                        </div>
                          <div className='card'>
                        <DataTable className='rdt_Table' columns={tableColumn}  data={MOBAccount=='' ? MOBAccount : MOBAccount}   
                            selectableRowsHighlight 
                            highlightOnHover
                            fixedHeader
                            pagination 
                            paginationPerPage={10}
                                //onSelectedRowsChange={getSelectedSubjects}
                           // selectableRows  
                                //selectableRowSelected={getPatientInfo} 
                                //</div> onRowClicked={getPatientInfo}
                            responsive={true}
                        >

                        </DataTable>

                        </div>
                    </div>
                  </div>

               
                </Modal.Body>

    </Modal>


      
    </div>
  )
}

export default AccountDeposit
