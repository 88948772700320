import React ,{useState ,useEffect ,useRef} from 'react'
import Swal from 'sweetalert2'
import { register  ,registerMenuItems ,SeletedUserDetails ,UserMenuItemsActionsAdmin ,deleteUserMenuSelected 
  ,UserList,updateUserBranchLevel ,SeletedProfileDetails} from '../actions/userAction'
import { useSelector ,useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import Message from '../Components/Message'
import Loader from '../Components/Loader'
import { USER_LOGOUT } from '../constants/userConstants'
import { Modal } from 'react-bootstrap';
import { FaPencil } from "react-icons/fa6";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Multiselect from 'multiselect-react-dropdown';

const LoginRegistration = () => {

    const [name , setName]= useState('')
    const [username ,setUsername] = useState('')
    const [password ,setPassword] = useState('')
    const [confirmPassword ,setConfirmPassword] = useState('')
    const [ loginstatus,setloginstatus] = useState('')
    const [ email,setEmail] = useState('')    
    const [save, setsave] = useState(false);
    const multiselectRefTracker=useRef()
   
    const [userId ,setUserId] =useState('')
    const [vUsername ,setVUsername] =useState('')
    const [branch ,setBranch]=useState('')
    const [vEmail ,setVEmail]=useState('')
    const [vStatus ,setVStatus]=useState('')
    const [is_active ,setis_active]=useState(false)
    const [Authorisation_Level , setAuthorisation_Level]=useState('')

    const dispatch =useDispatch()

    const userRegister=useSelector(state=>state.userRegister)
    const {loading ,error ,userInfo } = userRegister

    const SelectedUserInfo=useSelector(state=>state.SelectedUserInfo)
    const {SelectedUserDetails } = SelectedUserInfo


    const SelectedProfileInfo=useSelector(state=>state.SelectedProfileInfo)
    const {SelectedProfileDetails } = SelectedProfileInfo


    const userMenuItemsAdmin=useSelector(state=>state.userMenuItemsAdmin)
    const {MenuItemsAdmin ,MenuItemsAdminloading } = userMenuItemsAdmin

    const userMenuReducer=useSelector(state=>state.userMenuReducer)
    const {userMenuloading ,registereduserMenuItem } = userMenuReducer

    const deleteUserMenuItem=useSelector(state=>state.deleteUserMenuItem)
    const {deleted_Menu_Itemloading ,deleted_Menu_Itemtem } = deleteUserMenuItem


    const branchDetailsReducer=useSelector(state=>state.branchDetailsReducer)
    const {branchsInfo} = branchDetailsReducer
  
   
    const [showCreatNewUser , setShowCreatNewUser] =useState(false)

    const openCreateUser =()=>{
        setShowCreatNewUser(true)
    }

    const closeCreateUser =()=>{
        setShowCreatNewUser(false)
    }


    const ALLUserReducer = useSelector(state => state. ALLUserReducer)
    const {AllUser ,UserLoading ,UserSuccess}= ALLUserReducer


    const [data ,setDate]=useState(AllUser)

    const systemMenu=[
    
        {
          path:'/bank',
          name: 'BANK INFORMATION',
          icon:'<CiBank/>',
          narration:'BANK/CREDIT UNION DETAILS'
        },
  
        {
          path:'/bankbranch',
          name: 'BRANCH INFORMATION',
          icon:'<GiTreeBranch/>',
          narration:'BANK/CREDIT BRANCHS DETAILS'
        },
    
        {
          path:'/Product_Services',
          name: 'OUR PRODUCTS ',
          icon:'<MdAccountBalanceWallet />',
          narration:'BANK/CREDIT PRODUCTS PRODUCTS'

        },
  
        {
          path:'/servicer',
          name: 'ACCOUNT SERVICES ',
          icon:'<FaServicestack />',
          narration:'ACCOUNT SERVICES INFORMATION'
        },
  
        
        {
          path:'/loanProduct',
          name: 'LOAN PRODUCTS ',
          icon:'<GiReceiveMoney />',
          narration:'LOAN PRODUCTS'
        },
  
        {
          path:'/investment',
          name: 'INVESTMENT PRODUCTS',
          icon:'<GiPayMoney />',
          narration:'INVESTMENT PRODUCTS'
        },
  
            
        {
          path:'/membership',
          name: 'OUR MEMBERASHIP ',
          icon:'<MdOutlineRememberMe/>',
          narration:'MEMBERASHIP INFORMATION'
        },

        {
          path:'/corporateaccount',
          name: 'CORPORATE ACCOUNT',
          icon:'<FaUserGroup/>',
          narration:'CORPORATE ACCOUNT INFORMATION'
        },

        {
          path:'/individualaccount',
          name: 'INDIVIDUAL ACCOUNT',
          icon:'<FaUserPlus/>',
          narration:'INDIVIDUAL ACCOUNT INFORMATION'
        },

        {
          path:'/CustomerAccount',
          name: 'CUSTOMER ACCOUNT',
          icon:'<MdOutlineElectricalServices/>',
          narration:'CUSTOMER ACCOUNT INFORMATION'
        },

        {
          path:'/accountdeposit',
          name: 'ACCOUNT DEPOSIT',
          icon:'<GiCash/>',
          narration:'CUSTOMER ACCOUNT DEPOSIT'
        },

        {
          path:'/accountWthdrawal',
          name: 'ACCOUNT WITHDRAWAL',
          icon:'<FaCashRegister/>',
          narration:'CUSTOMER ACCOUNT WITHDRAWAL'
        },

        {
          path:'/VaultSetup',
          name: 'VAULT SETUP',
          icon:'<SiVault/>',
          narration:'VAULT SETUP INFORMATION'
        },

        {
          path:'/Vault',
          name: 'VAULT ACCOUNT',
          icon:'<FaVault/>',
          narration:'VAULT MANAGEMENT/ACCOUNT'
        },

        

        
  ]

  const usercolumns =[
        {
         name:'Id' ,
         selector:row=>row.id ,
         width: "90px" 
         
         ,wrap:true 
    
        } ,
    
         {
          name:'Username' ,
          selector:row=>row.username,
          width: "100px" 
          ,wrap:true
         },
    
         {
          name:'Email',
          selector:row=>row.email ,
          width: "190px" 
          ,wrap:true ,
          sortable:true
         },
    
         {
            name:'User',
            selector:row=>row.loginstatus ,
            width: "150px" 
            ,wrap:true ,
            sortable:true
           },
    
    
           {
           name:'view' , cell:row=> <button  onClick={()=>getUserDetails(row)} className='btt'> <FaPencil className='preview-btn'/> </button>,
           width:"70px"
          }  ,
    
         
        
    
      ]


      const getUserDetails=(row)=>{

        setBranch("Select")
        setAuthorisation_Level("Select")

        dispatch(SeletedUserDetails(row.id))
        dispatch(UserMenuItemsActionsAdmin(row.id))
        dispatch(SeletedProfileDetails(row.id))
        
        multiselectRefTracker.current.resetSelectedValues()
        
        
      }


      const handleChange=(e)=>{
       
        setis_active(!is_active)
         
        //console.log(is_active)
     }
    
    

    useEffect(()=> {

        if(save & userInfo !=null){

            Swal.fire({
                position: "center",
                icon: "success",
                title: "User Account Created",
                showConfirmButton: false,
                timer: 1500
              });

            setsave(false)
            setName('')
            setUsername('')
            setEmail('')
            setPassword('')
            setConfirmPassword('')
            setloginstatus('')

            //dispatch({type:USER_LOGOUT})

            dispatch(UserList())

            
        }

        if(SelectedUserDetails){

            setUserId(SelectedUserDetails.id)
            setVUsername(SelectedUserDetails.username)
            setVStatus(SelectedUserDetails.loginstatus)
            setVEmail(SelectedUserDetails.email)
            setis_active(SelectedUserDetails.is_active)

        }

        if(SelectedProfileDetails){

          setBranch(SelectedProfileDetails.branch)
          setAuthorisation_Level(SelectedProfileDetails.Authorisation_Level)
        }

    
    },[userInfo ,SelectedUserInfo ,userMenuItemsAdmin ,SelectedProfileInfo ])


    useEffect(()=>{

     

          dispatch(UserMenuItemsActionsAdmin(userId))

       

    },[userMenuReducer ,deleteUserMenuItem])


    useEffect(()=>{

      setDate(AllUser)

    },[ALLUserReducer])

    const add_user =(e) =>{

        e.preventDefault();
    
        if(password !== confirmPassword){

            Swal.fire({
                icon: 'error',
                title: 'Password Mismatch',
                text: 'Passwords do not Match',
                footer: 'Error Please Fix This'
              })
             

              setPassword('')
              setConfirmPassword('')
  
        }else {

            dispatch(register(name ,username ,loginstatus ,password ,email ))


            setsave(true)

            
    
        }

      
    
    }
    

    const changeBranch=(e)=>{

      e.preventDefault()
      setBranch(e.target.value)

      if(e.target.value=='Select'){

      }else {

        dispatch(updateUserBranchLevel({
          'user_id_id':userId ,
          'branch':e.target.value ,
          'Authorisation_Level':Authorisation_Level
        }))

      }
      
     
    }



    const changelevel=(e)=>{

      e.preventDefault()
      setAuthorisation_Level(e.target.value)

      if(e.target.value=='Select'){

      }
      
      dispatch(updateUserBranchLevel({
        'user_id_id':userId ,
        'branch':branch,
        'Authorisation_Level':e.target.value
      }))
    }



    const addMenuItem=(selected_data ,selectItem) => {
        //const getselecteddataLength = selected_data.length
       // console.log(getselecteddataLength)

       // console.log(selectItem.path)
        dispatch(registerMenuItems(selectItem.path ,selectItem.name ,selectItem.icon , userId ,selectItem.narration))
    }

    const removeMenuItem =(selected_data ,removedItem )  =>{

        //console.log(removedItem.trans_code)
        dispatch(deleteUserMenuSelected(removedItem.trans_code))
    }


  return (
    <div className='body'>

        <h3>System Users</h3>

        <hr/>

    <div className='ms-0 pe-0'>

 
    <button  onClick={openCreateUser} className='btn-class py-2 px-4 mt-2 mb-3'>Create New User</button>

    
    <div className='solcon overflow-auto vh-100'>

    <div className='row page-Margin-bottom'>
    <div className='col-md-6'>
    <div className='card '>

        
    {UserLoading && <Loader/>}   
   
                 <DataTable className='rdt_Table' columns={usercolumns}  data={data=='' ? data : data}   
                 selectableRowsHighlight 
                 highlightOnHover
                  fixedHeader
                   pagination 
                  paginationPerPage={7}
                    //onSelectedRowsChange={getSelectedSubjects}
                    selectableRows  
                     //selectableRowSelected={getPatientInfo} 
                    //</div> onRowClicked={getPatientInfo}
                    responsive={true}
                    >
                 </DataTable>  
    </div>
    </div>

    
    <div className='col-md-6'>
    <div className='card'>
    <div className='card-body'>
    
    <Tabs
      defaultActiveKey="User Details"
      id="justify-tab-example"
      className="mb-3"
      justify
    >

      <Tab eventKey="User Details" title="User Details">
       

      <div className="tab-pane fade show active profile-overview ms-5 me-5" id="User Details">


      <div className='row'>
                <div className='col-md-6'>
               
               <label for='Surname'>Id</label>
               <input name="Name" required type="text" disabled className="form-control" value={userId} onChange={(e)=>setUserId(e.target.value)} />              

               </div>


               <div className='col-md-6'>

                <label for='User Name'>Username</label>
                <input name="Name" required type="text" disabled value={vUsername} onChange={(e)=>setVUsername(e.target.value)} className="form-control" />

                </div>
                </div>


                <div className='mt-4'>
                <lable for='Login Status'>User Branch</lable>
                <select required  className='form-control' value={branch} onChange={changeBranch} >
                    <option selected value="Select" >Select</option>
                    {branchsInfo.map((branch)=>(
                       <option value={branch.name}>{branch.name}</option>
                    ))}
                   
                </select>

                </div>
                <div className='col-md-12 mt-4'>
               
                <label for='Surname'>Email</label>
                <input name="Name" required type="email" className="form-control" value={vEmail} onChange={(e)=>setVEmail(e.target.value)} />
                

                </div>
                <div className='col-md-12 mt-4'>

                <lable for='Login Status'>Select Login Status</lable>
                        <select required  className='form-control' value={vStatus} 
                       
                       onChange={(e)=>setVStatus(e.target.value)}>
                            <option disabled selected value="" >Select</option>
                            <option value="System Administrator">System Administrator</option>
                            <option value="Branch Manager">Branch Manager</option>
                            <option value="Operations Manager">Operations Manager</option>
                            <option value="General Manager">General Manager</option>
                            <option value="Mobile Banker Coordinator">Mobile Banker Coordinator</option>
                            <option value="Customer service representatives">Customer service representatives</option>
                            <option value="Vault Teller">Vault Teller</option>
                            <option value="Teller">Teller</option> 
                            <option value="Mobile Banker">Mobile Banker</option>
                            <option value="Loan Officer">Loan Officer</option>
                            <option value="Internal Auditor">Internal Auditor</option>

                          
                           
                        </select>
                        
                </div>


                <div className='col-md-12 mt-4'>
               
                  <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox"  checked={is_active} onChange={handleChange}
                   />
                  <label class="form-check-label" for="flexSwitchCheckChecked">Account Is Active</label>
                </div>
               

               </div>
            
               <button className='btn btn-primary mt-4 '>Save</button>
              

       </div>
      </Tab>


      <Tab eventKey="Functional Access" title="Functional Access">
       

       <div className="tab-pane fade show profile-overview ms-5 me-5" id="Functional Access">

        <div className='row'>
        <div className='col-md-6 mt-3'>
               
                <label for='Surname'>User Id</label>
                <input name="Name" disabled required type="text" className="form-control" value={userId} onChange={(e)=>setUserId(e.target.value)} />
                

                </div>
                <div className='col-md-6 mt-3'>

                <label for='User Name'>Username</label>
                <input name="Name" disabled required type="text" className="form-control"  value={vUsername} onChange={(e)=>setVUsername(e.target.value)}/>

                </div>    

          </div> 


      <label className='mt-3' for='User Access'>User Access</label>
      
      {MenuItemsAdminloading && <Loader/>}
      {userMenuloading && <Loader/>}
      
       <Multiselect
        displayValue="narration"
        onKeyPressFn={function noRefCheck(){}}
        onRemove={removeMenuItem}
        onSearch={function noRefCheck(){}}
        onSelect={addMenuItem}
        options={systemMenu}
        selectedValues={MenuItemsAdmin}
        ref={multiselectRefTracker}
        />

                
        </div>

       </Tab>



       <Tab eventKey="Authorisation Level" title="Authorisation Level">
       

       <div className="tab-pane fade show profile-overview ms-5 me-5" id="Functional Access">

       
      <div className='row'>
        <div className='col-md-6 mt-3'>
               
                <label for='Surname'>User Id</label>
                <input name="Name" disabled required type="text" className="form-control" value={userId} onChange={(e)=>setUserId(e.target.value)} />
                

                </div>
                <div className='col-md-6 mt-3'>

                <label for='User Name'>Username</label>
                <input name="Name" disabled required type="text" className="form-control"  value={vUsername} onChange={(e)=>setVUsername(e.target.value)}/>

                </div>    

          </div> 

      <div className='col-md-12 mt-4'>

                <lable for='Login Status'>Authorisation Level</lable>
                        <select required  className='form-control' value={Authorisation_Level} onChange={changelevel} >
                            <option value="Select" >Select</option>
                            <option value="0">Level 0</option>
                            <option value="1">Level 1</option>
                            <option value="2">Level 2</option>
                            <option value="3">Level 3</option>
                            <option value="4">Level 4</option>
                            <option value="5">Level 5</option>
                           
                        </select>

                </div>

              
        </div>

       </Tab>



    </Tabs>


    </div>

    </div>

    </div>


    </div>

    
    <Modal size='lg' show={showCreatNewUser} onHide={closeCreateUser} backdrop='static' keyboard={false}>

        <Modal.Header  closeButton>
            <Modal.Title>Add a New User</Modal.Title>
        </Modal.Header>

        <Modal.Body>

   
    <div className='content-r ps-5 pe-5 py-3'>

      
                <h3 className='signin-text'>Create New User Account</h3>

               

                <form onSubmit={add_user}>
                    {error && <Message variant='danger'>{error}</Message>}
                    {loading && <Loader/>}
                    <div className='form-group  pb-1'>
                        <label for='name'>Name:</label>
                        <input required  type='text' name='name' className='form-control' 
                        value={name} onChange={(e)=>setName(e.target.value)}/>
                    </div>

                    <div className='form-group mt-3'>
                        <lable for='username'>Username</lable>
                        <input required type='text' name='username' className='form-control'
                        value={username} onChange={(e)=>setUsername(e.target.value)}/>

                    </div>

                    <div className='form-group mt-3'>
                        <lable for='username'>Email</lable>
                        <input required type='email' name='Email' className='form-control'
                        value={email} onChange={(e)=>setEmail(e.target.value)}/>

                    </div>
                    
                    <div className='row'>
                    
                      <div className='col-md-6'>
                    <div className='form-group mt-3'>
                        <label for='password'>Password</label>
                        <input required type='password' name='password' className='form-control'
                        value={password} onChange={(e)=>setPassword(e.target.value)} />
                    </div>
                    </div>
                    <div className='col-md-6'>
                    <div className='form-group mt-3'>
                        <lable for='passwordconfirm'>Confirm password</lable>
                        <input required type='password' name='Confirm password' className='form-control'
                        value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}/>
                    </div>
                    </div>
                    </div>

                    <div className='form-group mt-3'>
                        <lable for='Login Status'>Select Login Status</lable>
                        <select required  className='form-control' value={loginstatus} 
                        onChange={(e)=>setloginstatus(e.target.value)}>
                           <option disabled selected value="" >Select</option>
                            <option value="System Administrator">System Administrator</option>
                            <option value="Branch Manager">Branch Manager</option>
                            <option value="Operations Manager">Operations Manager</option>
                            <option value="General Manager">General Manager</option>
                            <option value="Mobile Banker Coordinator">Mobile Banker Coordinator</option>
                            <option value="Customer service representatives">Customer service representatives</option>
                            <option value="Vault Teller">Vault Teller</option>
                            <option value="Teller">Teller</option> 
                            <option value="Mobile Banker">Mobile Banker</option>
                            <option value="Loan Officer">Loan Officer</option>
                            <option value="Internal Auditor">Internal Auditor</option>
                        </select>
                       

                    </div>
                    <button type='submit' className='show_info_btn mt-4'>Register New User</button>
                </form>

        </div>

   
    </Modal.Body>

    </Modal>

</div>
    </div>
</div>
  )
}

export default LoginRegistration

