import React ,{useState ,useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch ,useSelector } from 'react-redux';
import Message, { ErrorMessage } from '../Components/Message';
import Loader from '../Components/Loader';
import Swal from 'sweetalert2'
import moment from 'moment'
import { SaveNewVault,LoadVaultInfo ,getVaultDetailsAction } from '../actions/vaultAction';
import { VAULT_SET_RESET } from '../constants/vaultConstants';
import { FcHighPriority } from "react-icons/fc";
import { FcCheckmark } from "react-icons/fc";
import { approvalAction ,aprMessageAction  } from '../actions/approvalAction';
import { APPROVAL_SENT_REST } from '../constants/approvalConstants';
import CurrencyInput from 'react-currency-input-field';
import { ToWords } from 'to-words'
import { messageListAction } from '../actions/approvalAction';
import { APPROVAL_MESSAGE_SAVE_REST } from '../constants/approvalConstants';


function VaultSetup() {

    const [CreatNewVault ,setCreatNewVault]=useState(false)

    const ChartOfAccountReducer = useSelector(state => state. ChartOfAccountReducer)
    const {loadingchartOfAccount ,chartOfAccountsu ,chartOfAccount}= ChartOfAccountReducer


    const userProfileReducer = useSelector(state => state.userProfileReducer)
    const { userProfileInfo }=userProfileReducer


    const createVaultReducer = useSelector(state => state.createVaultReducer)
    const { creatValutLoading ,vaultsu ,creatValut }=createVaultReducer


    const vaultReducer = useSelector(state => state.vaultReducer)
    const { vaultLoading ,vault}=vaultReducer


    const VaultDetailsReducer = useSelector(state => state.VaultDetailsReducer)
    const { VaultDetailsInfo ,vaultDetailsLoading ,vaultDetailssuccess}=VaultDetailsReducer


    const userLogin = useSelector(state => state.userLogin)
    const { userInfo }=userLogin

    const approvalsReducer = useSelector(state => state.approvalsReducer)
    const { approvalsu }=approvalsReducer

    const ALLUserReducer = useSelector(state => state.ALLUserReducer)
    const { AllUser, UserLoading, UserSuccess } = ALLUserReducer

    const apro_Message_Reducer = useSelector(state => state.apro_Message_Reducer)
    const { aproMessageLoading, message, success } = apro_Message_Reducer

    const [prousers, setprousers] = useState([])

    const [map_acc_name ,setaccount]=useState('Select')
    const [map_acc_code ,setacc_code]=useState('')
    const [map_acc_type ,setacc_type]=useState('')
    const [map_acc_statment ,setacc_statement]=useState('')
    const [code ,setCode]=useState('')
    const [name ,setname]=useState('')
    const [balance ,setbalance ]=useState('')
    const [branch ,setbranch]=useState(userProfileInfo.branch)
    const [approval_by ,setapproval_by]=useState('')
    const [dat ,setdat]=useState(moment().format('MMMM Do YYYY, h:mm:ss a'))

    const dispatch=useDispatch()

useEffect(()=>{


  if(vaultsu){
    successMessage('Vault sucessfully Created')
    dispatch({type:VAULT_SET_RESET})
    celar()

    dispatch(LoadVaultInfo())
  }

},[createVaultReducer])


useEffect(()=>{

  dispatch(LoadVaultInfo())

  dispatch(messageListAction(userInfo.username))
  setprousers(AllUser.filter(obj => (obj.loginstatus == 'System Administrator' || obj.loginstatus == 'Branch Manager' || obj.loginstatus == 'Operations Manager'
    || obj.loginstatus == 'General Manager' || obj.loginstatus == 'Bank Administrator') && obj.username != userInfo.username))

    dispatch(messageListAction(userInfo.username))

},[dispatch])

useEffect(()=>{

  if(approvalsu){

    successMessage('Vault Information Loogged For De-Activation Authorization ')

    dispatch({type:APPROVAL_SENT_REST})

    dispatch(messageListAction(userInfo.username))
  }

},[approvalsReducer])




const celar=()=>{
  setCode('')
  setname('')
  setbalance('')
  setaccount('Select')
  setapproval_by('')

  setn200('')
  setn100('')
  setn50('')
  setn20('')
  setn10('')
  setn5('')
  setn2('')
  setn1('')
  setc2('')
  setc1('')
  setc05('')
  set02('')
  setc01('')
  setnotetotal(0)
  setCoinstotal(0)
  setruntotal(false)
  setgradeotal(0)
  setgetSaveDe(false)
  setamountToWord('') 

}
  
   const createNewVaultFunctionClose=() =>{
        setCreatNewVault(false)
        
        celar()
  }
  
  const successMessage=(message) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: message ,
            showConfirmButton: false,
            timer: 1500
          });
  }
      
  const successError=(message) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: message ,
            showConfirmButton: false,
            timer: 1500000
            
          
          });
      
  }
 

const saveVault=()=>{

  if(code=='' || name=='' || map_acc_name=='Select' || gradeotal==''){

    successError('Please All Required Fields Must Be Fill Out')

  }else {

    let dat2 =moment().format('YYYY-MM-DD')
    let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
    let level=userProfileInfo.Authorisation_Level
    let save_by=userInfo.username
    

    dispatch(SaveNewVault(code ,name ,gradeotal,dat ,dat2 ,map_acc_code ,map_acc_name ,map_acc_statment ,map_acc_type ,branch ,level ,save_by  
      ,n200 , n100 ,n50 ,n20 ,n10 ,n5 ,n2 ,n1, c2 , c1 ,c05 ,c02 , c01 ,amountToWord ))
  }

}

const mapAccount=(e)=>{

    const selectedAccount=chartOfAccount.find(obj=>obj.name==e.target.value)
    console.log(selectedAccount)

   setaccount(selectedAccount.name)
 
   setacc_code(selectedAccount.acc_code)
   setacc_type(selectedAccount.acc_type)
   setacc_statement(selectedAccount.acc_statement)
  }

  const getVaultDetails=(va)=>{

    dispatch(getVaultDetailsAction(va))


    setCreatNewVault(true)
  }

  const [table_pkk ,settable_pk]=useState('')
  const [vname ,setvname]=useState('')
  const [vcode ,setvcode]=useState('')
  const [va ,setva]=useState('')

  const makeInActive=(va)=>{

    settable_pk(va.code)
    setvname(va.name)
    setvcode(va.code)
    setwhyMessageRequst("Request For Authorization To DeActivate " + va.name +" Vault Information With Vault Code " + va.code)
    setdat( moment().format('MMMM Do YYYY, h:mm:ss a'))
    setva(va)
    openValtapp()  

    
  }


  const makeActive=(va)=>{
    
    settable_pk(va.code)
    setvname(va.name)
    setvcode(va.code)
    setwhyMessageRequst("Request For Authorization To Activate " + va.name +" Vault Information With Vault Code " + va.code)
    setdat( moment().format('MMMM Do YYYY, h:mm:ss a'))
    setva(va)
    openValtapp()
    
   
}

const makeValutActivat=() =>{

  if(approval_by==''){

    ErrorMessage('Select Request To')

  }else {

    console.log(table_pkk)

    const apm = message.filter(obj => obj.table == 'Valut Table' && obj.approval_status == false && obj.table_pk == table_pkk && obj.message_sum == whyMessageRequst)

    console.log(apm)
    if (apm.length > 0) {

        ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

    } else {

    let table = 'Valut Table'
    let action = 1
    let sent_by = userInfo.username
    let pic = userProfileInfo.image
    let dat = moment().format('YYYY-MM-DD')
    let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
    let title = 'Valut Request'
    let message_sum = whyMessageRequst
  
    let name = vname
    let accnumber = vcode
    let RequestMessage = "Requesting For Authorization On Valut"
    let acc_ty='-'
  

    dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pkk, pic, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, '-', '-', '-'))
   
  }

  }

    
}

const makeValutinActivat=() =>{

  if(approval_by==''){

    ErrorMessage('Select Request To')

  }else {

    console.log(table_pkk)

    const apm = message.filter(obj => obj.table == 'Valut Table' && obj.approval_status == false && obj.table_pk == table_pkk && obj.message_sum == whyMessageRequst)

    console.log(apm)
    if (apm.length > 0) {

        ErrorMessage(whyMessageRequst + ' Already Sent For Approval')

    } else {

    let table = 'Valut Table'
    let action = 1
    let sent_by = userInfo.username
    let pic = userProfileInfo.image
    let dat = moment().format('YYYY-MM-DD')
    let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
    let title = 'Valut Request'
    let message_sum = whyMessageRequst
  
    let name = vname
    let accnumber = vcode
    let RequestMessage = "Requesting For Valut De-activation"
    let acc_ty='-'
  

    dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pkk, pic, dat, dat2, title, message_sum, approval_by, acc_ty, name, accnumber, '-', '-', '-'))
   
  }

  }

    
}


  useEffect(()=>{

    if(vaultDetailssuccess ){

      setCode(VaultDetailsInfo.code)
      setname(VaultDetailsInfo.name)
      setbalance(VaultDetailsInfo.balance)
      setaccount(VaultDetailsInfo.map_acc_name)
      setapproval_by(VaultDetailsInfo.approval_by)
      
      setn200(VaultDetailsInfo.n200)
      setn100(VaultDetailsInfo.n100)
      setn50(VaultDetailsInfo.n50)
      setn20(VaultDetailsInfo.n20)
      setn10(VaultDetailsInfo.n10)
      setn5(VaultDetailsInfo.n5)
      setn2(VaultDetailsInfo.n2)
      setn1(VaultDetailsInfo.n1)
     
      setc2(VaultDetailsInfo.c2)
      setc1(VaultDetailsInfo.c1)
      setc05(VaultDetailsInfo.c05)
     
      set02(VaultDetailsInfo.c02)

      setc01(VaultDetailsInfo.c01)

      sett200((Number(VaultDetailsInfo.n200) * 200))
      sett100((Number(VaultDetailsInfo.n100) * 100))
      sett50((Number(VaultDetailsInfo.n50) * 50))
      sett20((Number(VaultDetailsInfo.n20) * 20))
      sett10((Number(VaultDetailsInfo.n10) * 10))
      sett5((Number(VaultDetailsInfo.n5) * 5))
      sett2((Number(VaultDetailsInfo.n2) * 2))
      sett1((Number(VaultDetailsInfo.n1) * 1))

      settc2((Number(VaultDetailsInfo.c2) * 2))
      settc1((Number(VaultDetailsInfo.c1) * 1))
      settc05((Number(VaultDetailsInfo.c05) * 0.5))
      setc02((Number(VaultDetailsInfo.c02) * 0.2))
      settcc01((Number(VaultDetailsInfo.c01) * 0.1))


      setruntotal(true)
      setgetSaveDe(true)
      
    }

  },[VaultDetailsReducer])

  const save_Message_Reducer = useSelector(state => state.save_Message_Reducer)
  const { messagesave, messagesaveLoading, savesuccess } = save_Message_Reducer

  useEffect(() => {

    if (savesuccess) {
       
        //playOn()

        dispatch({type: APPROVAL_MESSAGE_SAVE_REST })

        dispatch(messageListAction(userInfo.username))

        colseValtapp()
        

    }


}, [save_Message_Reducer])


  const [n200 ,setn200]=useState('')
  const [t200 ,sett200]=useState(0)

  const [n100 ,setn100]=useState('')
  const [t100 ,sett100]=useState(0)
 
  const [n50 ,setn50]=useState('')
  const [t50 ,sett50]=useState(0)

  const [n20 ,setn20]=useState('')
  const [t20 ,sett20]=useState(0)

  const [n10 ,setn10]=useState('')
  const [t10 ,sett10]=useState(0)

  const [n5 ,setn5]=useState('')
  const [t5 ,sett5]=useState(0)

  const [n2 ,setn2]=useState('')
  const [t2 ,sett2]=useState(0)

  const [n1 ,setn1]=useState('')
  const [t1 ,sett1]=useState(0)


  const [c2 ,setc2]=useState('')
  const [tc2 ,settc2]=useState(0)

  const [c1 ,setc1]=useState('')
  const [tc1 ,settc1]=useState(0)

  const [c05 ,setc05]=useState('')
  const [tc05 ,settc05]=useState(0)

  const [c02 ,set02]=useState('')
  const [tcc02 ,setc02]=useState(0)

  const [c01 ,setc01]=useState('')
  const [tcc01 ,settcc01]=useState(0)

  const [notetotal ,setnotetotal]=useState(0)

  const [Coinstotal ,setCoinstotal]=useState(0)

  const [runtotal , setruntotal]=useState(false)

  const [gradeotal , setgradeotal]=useState(0)

  const [getSaveDe ,setgetSaveDe]=useState(false)

  const [amountToWord ,setamountToWord]=useState('') 

  const [whyMessageRequst, setwhyMessageRequst] = useState('')

  const totals =()=>{

    var note_total=(Number(t200) + Number(t100) +Number(t50) + Number(t20) + Number(t10) + Number(t5) +Number(t2) + Number(t1))
    setnotetotal(note_total)

    var Coins_total=(Number(tc2) + Number(tc1) +Number(tc05) + Number(tcc02) + Number(tcc01) )
    setCoinstotal(Coins_total)

    setruntotal(false)


    setgradeotal(Number(note_total) + Number(Coins_total) )

    //setamount(Number(note_total) + Number(Coins_total))

  }

 useEffect(()=>{

  if(runtotal){
    totals()
  }

 },[runtotal])


 const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: 'Ghana Cedis',
      plural: 'Ghana Cedis',
      symbol: '₹',
      fractionalUnit: {
        name: 'Pesewas',
        plural: 'Pesewas',
        symbol: '',
      },
    },
  },
})

 useEffect(()=>{

  let words = toWords.convert(gradeotal, { currency: true });
  
    setamountToWord(words)


 },[gradeotal]) 

 const [valtapp ,setvaltapp]=useState(false)

 const openValtapp=()=>{
  setvaltapp(true)
  
 }

 const colseValtapp=()=>{
  setvaltapp(false)
  settable_pk('')
  setvname('')
  setvcode('')
  setapproval_by('')
 }

 

  return (
    <div>
        
        <h3>Vault Setup</h3>

                    
        <hr/>

        <button onClick={() => {setCreatNewVault(true)}} className='btn-class py-2 px-4 mt-2 mb-3'>Create New Vault </button>


        <div className='solcon overflow-auto vh-100'>

       
        {vaultLoading && <Loader/>}
        {vaultDetailsLoading && <Loader/>}
       
        <div className='bra row me-2'>

                {vault && vault.map(va=>(

                <div className='col-md-4 mb-4'>
                    <div className="card">
                        <div className="card-body">
                        <h4 className="card-title">{va.name}</h4>
                        <ul className="list-group">
                            <li className="list-group-item">Vault Code : {va.code}</li>
                            <li className="list-group-item">Vault Branch : {va.branch}</li>
                            <div className="card-body profile-card pt-3  d-flex flex-column align-items-center">
                            {va.save_aproval ? 
                            ( 
                              <button onClick={()=>makeInActive(va)} className="list-group-item btn-class"> Vault Is Active <FcCheckmark /> Make Vault In-Active</button>
                              
                            )
                           
                              : 

                              <button  onClick={()=>makeActive(va)} className="list-group-item btn-class"> Vault Is In-Active <FcHighPriority /> Make Vault Active</button>
                             
                              
                              }
                            </div>
                        </ul>

                        </div>
                        <button onClick={()=>getVaultDetails(va.code)}   className='show_info_btn'>View Vault Details</button>
                    </div>
                    </div>

                                
                ))}

                </div>
                
        </div>



    <Modal size='lg' show={CreatNewVault} onHide={createNewVaultFunctionClose} dialogClassName="my-modal-small"  backdrop='static' keyboard={false}>

    <Modal.Header  closeButton>
            <Modal.Title>Vault Infomation</Modal.Title>
        </Modal.Header>

    <Modal.Body>

      <div className='row mt-3'>

        <div className='col-md-4'>
        <div className='form-group'>
            <label className='lable' for='service code'><samp className='text-danger'>* </samp>Vault Code</label>
            <input type='number' value={code} onChange={(e)=>setCode(e.target.value)} required className='form-control' name='service code'/>
          
        </div>
        </div>


      <div className='col-md-4'>
      <div className='form-group'>

      <label className='lable' for='service name'><samp className='text-danger'>* </samp>Vault Name</label>
      <input type='type' value={name} onChange={(e)=>setname(e.target.value)}  required className='form-control' name='service Name'/>

      </div>
      </div>


      
      <div className='col-md-4'>
        <div className='form-group '>
            <label className='lable' for='product Name'><samp className='text-danger'>* </samp> Map Accounts</label>
                                        
            <select required  className='form-control'onChange={mapAccount} value={map_acc_name}  >
             <option required disabled selected value="Select" >Select</option>
              {chartOfAccount.map((chart)=>(
              <option value={chart.name}>{chart.name}</option>
              ))} 
                                                        
           </select>
        </div>
        </div>

       




      </div>

      <div className='card mt-3'>
              <div className='card-header userbal d-flex flex-column align-items-center'>Denomination</div>

              {creatValutLoading && <Loader/>}

              <div className='card-body ms-4 me-4'>
              <div className="card">
              <div className="card-body">
                <div className='row'>

                <div className='col-md-6 col-lg-6'>

                <div className='row'>
                  <div className='col-md-3 col-lg-3'>
                  
                      <h5 className="card-title  text-center ">Note</h5>
                      <hr/>
                      <input disabled name="number"  type="number" className="form-control  text-center" value={200} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={100} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={50} />
                      <input disabled  name="number"  type="number" className="form-control text-center" value={20}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={10}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={5}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={2}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={1}/>

                  </div>
                  <div className='col-md-5 col-lg-5'>
                  <h5 className="card-title text-center">Pieces </h5>
                  <hr/>
                      {getSaveDe ? <input value={n200}  name="number"  type="number" className="form-control text-center" />
                      : 
                      <input  value={n200}  onChange={(e)=>{
                         setn200(e.target.value)
                         if(e.target.value==''){
                          sett200(0)
                          
                         // setn200(0)
                        }else {

                          sett200((Number(e.target.value) * 200))
                         
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }

                      {getSaveDe ? <input  value={n100}  name="number"  type="number" className="form-control text-center" />
                      :
                     <input value={n100} onChange={(e)=>{
                         setn100(e.target.value)
                         if(e.target.value==''){
                          sett100(0)
                         // setn200(0)
                        }else {

                          sett100((Number(e.target.value) * 100))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }
                       {getSaveDe ? <input  value={n50}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n50} onChange={(e)=>{
                         setn50(e.target.value)
                         if(e.target.value==''){
                          sett50(0)
                         // setn200(0)
                        }else {

                          sett50((Number(e.target.value) * 50))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />

                    }
                      {getSaveDe ? <input  value={n20}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n20} onChange={(e)=>{
                         setn20(e.target.value)
                         if(e.target.value==''){
                          sett20(0)
                         // setn200(0)
                        }else {

                          sett20((Number(e.target.value) * 20))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                      {getSaveDe ? <input  value={n10}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n10} onChange={(e)=>{
                         setn10(e.target.value)
                         if(e.target.value==''){
                          sett10(0)
                         // setn200(0)
                        }else {

                          sett10((Number(e.target.value) * 10))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }
                       {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n5} onChange={(e)=>{
                         setn5(e.target.value)
                         if(e.target.value==''){
                          sett5(0)
                         // setn200(0)
                        }else {

                          sett5((Number(e.target.value) * 5))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                      {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                        <input value={n2} onChange={(e)=>{
                         setn2(e.target.value)
                         if(e.target.value==''){
                          sett2(0)
                         // setn200(0)
                        }else {

                          sett2((Number(e.target.value) * 2))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />
                    }

                    {getSaveDe ? <input  value={n5}  name="number"  type="number" className="form-control text-center" />
                      :
                    <input value={n1} onChange={(e)=>{
                         setn1(e.target.value)
                         if(e.target.value==''){
                          sett1(0)
                         // setn200(0)
                        }else {

                          sett1((Number(e.target.value) * 1))
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control text-center"  />

                    }

                  </div>
                  <div className='col-md-4 col-lg-4'>
                  <h5 className="card-title text-center">Total</h5>
                  <hr/>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    fixedDecimalLength={2}
                    className='form-control text-center'
                    value={t200}
                    decimalScale={2}
                    
                    />
                    
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t100}
                    decimalScale={2}
                    
                    />
                    
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t50}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t20}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t10}
                    decimalScale={2}
                    
                    />
                     <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t5}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t2}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={t1}
                    decimalScale={2}
                    
                    />

                  </div>
                  </div>
                  <hr/>
                  <div className='row'>
                    <div className='col-md-6'>
                    <h5 className="card-title text-center">Note Total GH₵:</h5>
                    </div>

                    <div className='col-md-6'>
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={notetotal}
                    decimalScale={2}
                    
                    />
                    </div>
                  </div>
                  </div>
                  
                  <div className='col-md-6 col-lg-6'>

                  <div className='row'>
                  <div className='col-md-3 col-lg-3'>
                  
                      <h5 className="card-title text-center">Coins</h5>
                      <hr/>
                      <input disabled name="number"  type="number" className="form-control  text-center" value={2} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={1} />
                      <input disabled name="number"  type="number" className="form-control text-center" value={0.5} />
                      <input disabled  name="number"  type="number" className="form-control text-center" value={0.2}/>
                      <input disabled name="number"  type="number" className="form-control text-center" value={0.1}/>
                     
                      
                  </div>
                  <div className='col-md-5 col-lg-5'>
                  <h5 className="card-title text-center">Pieces</h5>
                  <hr/>
                  {getSaveDe ? <input  value={c2}  name="number"  type="number" className="form-control text-center" />
                    :
                  <input value={c2} onChange={(e)=>{
                         setc2(e.target.value)
                         if(e.target.value==''){
                          settc2(0)
                          
                         // setn200(0)
                        }else {

                          settc2((Number(e.target.value) * 2))
                             
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }
                      {getSaveDe ? <input  value={c1}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c1} onChange={(e)=>{
                         setc1(e.target.value)
                         if(e.target.value==''){
                          settc1(0)
                          
                         // setn200(0)
                        }else {

                          settc1((Number(e.target.value) * 1))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>

                    }
                     {getSaveDe ? <input  value={c05}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c05} onChange={(e)=>{
                         setc05(e.target.value)
                         if(e.target.value==''){
                          settc05(0)
                          
                         // setn200(0)
                        }else {

                          settc05((Number(e.target.value) * 0.5))
                         
                        
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }

                    {getSaveDe ? <input  value={c02}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c02} onChange={(e)=>{
                         set02(e.target.value)
                         if(e.target.value==''){
                          setc02(0)
                          
                         // setn200(0)
                        }else {

                          setc02((Number(e.target.value) * 0.2))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>

                    }

                    {getSaveDe ? <input  value={c01}  name="number"  type="number" className="form-control text-center" />
                    :
                        <input value={c01} onChange={(e)=>{
                         setc01(e.target.value)
                         if(e.target.value==''){
                          settcc01(0)
                          
                         // setn200(0)
                        }else {

                          settcc01((Number(e.target.value) * 0.1))
                         
                          
                          
                        }
                        setruntotal(true)
                      }} name="number"  type="number" className="form-control  text-center"/>
                    }
                  </div>
                  <div className='col-md-4 col-lg-4'>
                  <h5 className="card-title text-center ">Total</h5>
                  <hr/>
                  
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc2}
                    decimalScale={2}
                    
                    />
                 
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc1}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tc05}
                    decimalScale={2}
                    
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tcc02}
                    decimalScale={2}
                    />

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={tcc01}
                    decimalScale={2}
                    />



                  </div>
                  </div>
                  <hr/>
                  <div className='row'>
                    <div className='col-md-6'>
                    <h5 className="card-title text-center">Coins Total GH₵:</h5>
                    </div>

                    <div className='col-md-6'>

                    <CurrencyInput
                    
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    fixedDecimalLength={2}
                    value={Coinstotal}
                    decimalScale={2}

                    />
                    </div>
                  </div>

                  <div className='mt-5'>
                    
                  </div>
                  </div>
                  
                </div> 
                </div>
                <hr/>
                <div className='row mb-3'>
                    <div className='col-md-3'>
                    <h5 className="card-title text-center"><samp className='text-danger'>* </samp>Grand Total GH₵:</h5>
                    </div>

                    <div className='col-md-2'>

                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-center'
                    value={gradeotal}
                    decimalScale={2}
                    
                    />
                    </div>


                    <div className='col-md-4'>

                   
                    <input value={amountToWord} name="Name" required type="text" className="form-control text-center" />
                    </div>


                   
                  </div>

                  

                </div>

              </div>

              </div>

        {getSaveDe ? '' :

        creatValutLoading ? '' :

        <button onClick={saveVault} className="show_info_btn mt-4">Save Vault Information</button> 
        
         }

     

    </Modal.Body>


</Modal>




<Modal size="lg" show={valtapp} onHide={colseValtapp} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title> Vault Setup Approval </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                {aproMessageLoading && <Loader/>}
                {messagesaveLoading && <Loader/>}

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request</label>
                    <input name="Name" type="text" className="form-control mb-4 text-center" value={whyMessageRequst}  />


                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Request To</label>
                    <select required className='form-control mb-4' value={approval_by} onChange={(e)=>setapproval_by(e.target.value)} >
                        <option selected disabled value="" >Select</option>
                        {prousers.map((users) => (
                            <option value={users.username}>{users.name} - {users.loginstatus} </option>
                        ))}

                    </select>

                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp> Request Date /Time</label>
                    <input name="Name" type="text" className="form-control mb-4" value={dat}  />

                    {messagesaveLoading ? '':
                    
                    va.save_aproval==1 ? 
                    
                    <button onClick={makeValutinActivat} className="show_info_btn">Make Valut In-Activation </button>
                    :

                     <button onClick={makeValutActivat} className="show_info_btn">Request Valut Activation</button>
                     
                     }
                   


                </Modal.Body>

            </Modal>


      
    </div>
  )
}

export default VaultSetup
